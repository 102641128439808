import { Button, Form, Menu, Row } from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { cloneDeep, get, set, startCase, unset } from "lodash";

import { arrayMove } from "utils/utils";
import { colors } from "theme/colors";
import { componentIdOptions } from "utils/utils";
import styled from "styled-components";
import { useState } from "react";

export const badges = {
  red: "#df516e40",
  orange: "#f79e4440",
  yellow: "#f7c74440",
  green: "#74c98f40",
  blue: "#429ef040",
  purple: "#6945e140",
};

const ItemContainer = styled.div`
  margin-left: 20px;
  border: 1px solid ${colors.inputBorder};
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;
  min-width: 200px;
  background: white;
  ${(p) => p.active && `outline: 2px solid ${colors.primary};`}
`;

const Item = styled.div``;

const Key = styled.div`
  font-weight: bold;
`;

const Type = styled.div`
  background: ${(props) => {
    switch (props.type) {
      case "string":
        return badges.blue;
      case "number":
        return badges.green;
      case "boolean":
        return badges.orange;
      case "object":
        return badges.purple;
      case "array":
        return badges.yellow;
      default:
        return colors.grey2;
    }
  }};
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 13px;
  width: fit-content;
`;

const Hierarchy = ({ data, onChange }) => {
  const [activePath, setActivePath] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [tab, setTab] = useState("visual");
  const [localData, setLocalData] = useState(null);

  const renderHierarchy = (obj, level = 0, path = [], isObjOrArray = false) => {
    return (
      <Container
        style={{ display: "flex", flexDirection: "column" }}
        dragHandleSelector=".drag-item"
        lockAxis="y"
        onDrop={(e) => {
          const { addedIndex, removedIndex } = e;

          let newHierarchy = cloneDeep(data);

          if (path.length === 0) {
            const movedItems = arrayMove(
              newHierarchy,
              removedIndex,
              addedIndex
            );
            onChange(movedItems);
          } else {
            const finalPath = isObjOrArray ? [...path, "keys"] : path;
            const currentItems = get(newHierarchy, finalPath, []);
            const movedItems = arrayMove(
              currentItems,
              removedIndex,
              addedIndex
            );
            set(newHierarchy, finalPath, movedItems);
            onChange(newHierarchy);
          }
        }}
      >
        {obj.map((item, index) => {
          const keys = isObjOrArray ? ["keys"] : [];

          const currentPath = [...path, ...keys, index];

          const arrayType = get(item, "array_type", "string");

          const itemKey = get(item, "key", "");

          return (
            <Draggable key={index}>
              <div key={item.key} className="drag-item">
                <ItemContainer
                  style={{ marginLeft: `${40 + level * 40}px` }}
                  onClick={(event) => {
                    setActivePath(currentPath);
                    setAnchorElement(event.currentTarget);
                    setLocalData(item);
                  }}
                  active={
                    activePath && activePath.join(",") === currentPath.join(",")
                  }
                >
                  <Item>
                    <Row
                      alignItems="center"
                      justifyContent="space-between"
                      gap="20px"
                    >
                      <Key>{item.key}</Key>
                      <Type type={item.type}>{startCase(item.type)}</Type>
                    </Row>
                  </Item>
                </ItemContainer>

                {(item.type === "object" ||
                  (item.type === "array" && arrayType === "object")) && (
                  <Button
                    data={{
                      text: "Add Field",
                      type: "basic",
                      size: "small",
                      icon: "FiPlus",
                      margin: `0 0 5px 80px`,
                      onClick: () => {
                        addField(currentPath);
                      },
                    }}
                  />
                )}

                {["object", "array"].includes(item.type) &&
                  item.keys &&
                  renderHierarchy(
                    get(item, "keys", []),
                    level + 1,
                    currentPath,
                    true
                  )}
              </div>
            </Draggable>
          );
        })}
      </Container>
    );
  };

  // Save changes
  const save = () => {
    // Handle data changed but key not changed
    let newHierarchy = cloneDeep(data);

    set(newHierarchy, activePath, localData);

    onChange(newHierarchy);
    setActivePath(null);
  };

  // Handle adding a new field to the hierarchy
  const addField = (path) => {
    const newField = {
      key: "new_field",
      type: "string", // Default type, can be modified later
      description: "", // Default description
    };

    let newHierarchy = cloneDeep(data);

    if (path.length === 0) {
      const newData = [...newHierarchy, newField];
      newHierarchy = newData;
    } else {
      const currentItem = get(data, path, []);
      const arrayKeys = get(currentItem, "keys", []);
      const newArrayKeys = [...arrayKeys, newField];
      const newObj = {
        ...currentItem,
        keys: newArrayKeys,
      };

      set(newHierarchy, path, newObj);
    }

    onChange(newHierarchy);
  };

  const deleteField = (path) => {
    const newHierarchy = cloneDeep(data);
    const match = get(newHierarchy, path, []);
    const parentPath = path.slice(0, -1);
    const parent = get(newHierarchy, parentPath, []);

    const newParent = parent.filter((p) => p.key !== match.key);
    set(newHierarchy, parentPath, newParent);
    onChange(newHierarchy);
    setActivePath(null);
    setAnchorElement(null);
    setLocalData(null);
  };

  const fields = [
    {
      id: "key",
      label: "Key",
      componentId: "Input",
      value: get(localData, "key"),
    },
    {
      id: "type",
      label: "Type",
      componentId: "Select",
      value: get(localData, "type"),
      options: [
        {
          label: "String",
          value: "string",
        },
        {
          label: "Number",
          value: "number",
        },
        {
          label: "Boolean",
          value: "boolean",
        },
        {
          label: "Object",
          value: "object",
        },
        {
          label: "Array",
          value: "array",
        },
      ],
    },
    {
      id: "array_type",
      label: "Array Type",
      componentId: "Select",
      value: get(localData, "array_type", "string"),
      options: [
        {
          label: "String",
          value: "string",
        },
        {
          label: "Number",
          value: "number",
        },
        {
          label: "Object",
          value: "object",
        },
      ],
      displayCondition: () => get(localData, "type") === "array",
    },
    {
      id: "is_enum",
      label: "Enum",
      hint: "This value will only be generated from a specific set of options",
      componentId: "Switch",
      value: get(localData, "is_enum", false),
      displayCondition: () =>
        ["string", "number"].includes(get(localData, "type")),
    },
    {
      id: "description",
      label: "Description",
      componentId: "TextArea",
      value: get(localData, "description"),
    },
    {
      id: "placeholder",
      label: "Placeholder",
      componentId: "Input",
      value: get(localData, "placeholder"),
    },
    // {
    //   id: "hide_label",
    //   label: "Hide Label",
    //   componentId: "Switch",
    //   value: get(localData, "hide_label", false),
    // },
    {
      id: "componentId",
      label: "Display Component",
      componentId: "Select",
      value: get(localData, "componentId", "Input"),
      defaultValue: "Input",
      hideEmptyItem: true,
      options: componentIdOptions,
    },
    {
      id: "options",
      label: "Options",
      componentId: "KeyValuePairs",
      hint: "Options for the select dropdown and the Enum option",
      useLabelAsKey: true,
      value: get(localData, "options", []),
      displayCondition: (localData) =>
        get(localData, "componentId") === "Select" ||
        get(localData, "is_enum", false),
    },
  ];

  return (
    <div>
      {activePath && (
        <Menu
          background={"white"}
          anchorElement={anchorElement}
          hide={() => {
            setAnchorElement(null);
            setLocalData(null);
            setActivePath(null);
          }}
          label={"Edit Field"}
          width={"300px"}
        >
          <Form
            submit={save}
            submitText="Save"
            buttons={[
              {
                text: "Delete",
                type: "basic",
                onClick: () => deleteField(activePath),
              },
            ]}
            onChange={(k, v) => setLocalData({ ...localData, [k]: v })}
            fields={fields.filter(
              (f) =>
                !f.displayCondition ||
                (f.displayCondition && f.displayCondition(localData))
            )}
          />
        </Menu>
      )}
      {/* <Tabs
        data={{
          margin: "0 0 20px 0",
          tabs: [
            {
              label: "Visual",
              active: tab === "visual",
              onClick: () => setTab("visual"),
            },
            {
              label: "JSON Preview",
              active: tab === "json",
              onClick: () => setTab("json"),
            },
          ],
        }}
      /> */}

      <Button
        data={{
          text: "Add Field",
          type: "basic",
          size: "small",
          icon: "FiPlus",
          margin: "0 0 5px 0",
          onClick: () => {
            addField([]);
          },
        }}
      />

      {tab === "visual" && renderHierarchy(data, 0, [])}
      {/* {tab === "json" && (
        <pre style={{ fontSize: "14px", background: colors.grey1 }}>
          {JSON.stringify(convertToSimplifiedData(data), null, 2)}
        </pre>
      )} */}
    </div>
  );
};

export default Hierarchy;

import "react-notifications-component/dist/theme.css";

import { ConfirmationModal, FullScreenLoader } from "components";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { rIsFetchingConfig, rOrganization } from "utils/recoil";

import Cookies from "js-cookie";
import Flow from "pages/Flow/Flow";
import FlowEditor from "pages/FlowEditor/FlowEditor";
import Flows from "pages/Flows";
import Login from "pages/Login";
import { ReactNotifications } from "react-notifications-component";
import Templates from "pages/Templates";
import { isEmpty } from "lodash";
import useAdmin from "hooks/useAdmin";
import { useEffect } from "react";
import useFetchAppConfig from "hooks/useFetchAppConfig";
import { useRecoilValue } from "recoil";

const App = () => {
  useFetchAppConfig();

  const isFetchingConfig = useRecoilValue(rIsFetchingConfig);

  const organization = useRecoilValue(rOrganization);

  const { isAdmin, isPublic } = useAdmin();

  // check if user is logged in and if not, redirect to login
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  useEffect(() => {
    if (isAdmin && !Cookies.get("accessToken")) {
      navigate("/login");
    }
  }, [pathname]);

  const showSpinner = isAdmin && isFetchingConfig && isEmpty(organization);

  if (showSpinner) {
    return <FullScreenLoader />;
  }

  return (
    <div>
      <ReactNotifications style={{ zIndex: 2000 }} />
      <Routes>
        {isPublic && (
          <>
            <Route path="login" element={<Login type="login" />} />
            <Route path="signup" element={<Login type="signup" />} />
            <Route path="flow/:id/" element={<Flow />} />
            <Route path="flow/:id/:sessionId" element={<Flow />} />
            <Route path="" element={<Login />} />
          </>
        )}

        {isAdmin && (
          <>
            <Route path="flows" element={<Flows />} />
            <Route path="templates" element={<Templates />} />
            <Route path="flow/edit/:id" element={<FlowEditor />} />
            {/* <Route path="billing" element={<Billing />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="files" element={<Files />} /> */}
          </>
        )}
      </Routes>
      <ConfirmationModal />
    </div>
  );
};

export default App;

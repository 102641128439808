import { Button, Text } from ".";
import { get, isNil } from "lodash";
import { rOrganization, rTranslations, rUser } from "utils/recoil";

import { colors } from "theme/colors";
import { getCloudinaryWidget } from "./ImageUpload";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const FileUpload = ({ data }) => {
  return <div>FileUpload</div>;
  // const [state, stateSet] = useState({
  //   isFetching: false,
  //   settings: {},
  //   errors: {},
  // });

  // const user = useRecoilValue(rUser);
  // const activeApp = useRecoilValue(rOrganization);
  // const translations = useRecoilValue(rTranslations);

  // const showWidget = () => {
  //   if (data.isAdmin || !isAdmin) {
  //     stateSet((oldState) => ({ ...oldState, isFetching: true }));

  //     const uploadWidget = getCloudinaryWidget({
  //       activeApp,
  //       translations,
  //       user,
  //       stateSet,
  //       onAssetUploaded: data.onAssetUploaded,
  //       onChange: data.onChange,
  //     });

  //     uploadWidget.open();
  //   }
  // };

  // const url = data.value;
  // let fileName = null;
  // if (url) {
  //   const split = url.split("/");
  //   if (split.length) {
  //     fileName = split[split.length - 1];
  //   }
  // }

  // return (
  //   <>
  //     {!data.hideFilename && (
  //       <Text
  //         data={{
  //           text: fileName || get(translations, "noFile", "No File"),
  //           margin: "0 0 5px 0",
  //           color: data.color || colors.grey3,
  //           fontStyle: "bodySm",
  //         }}
  //       />
  //     )}
  //     {isNil(url) || url === "" ? (
  //       <Button
  //         data={{
  //           isFetching: state.isFetching,
  //           text: data.text || get(translations, "selectFile", "Select File"),
  //           size: data.size || "small",
  //           type: data.buttonType || "basic",
  //           icon: data.icon || "FiUploadCloud",
  //           onClick: showWidget,
  //         }}
  //       />
  //     ) : (
  //       <Button
  //         data={{
  //           isFetching: state.isFetching,
  //           text: "Remove",
  //           size: "small",
  //           type: "basic",
  //           icon: "FiX",
  //           onClick: () => data.onChange(""),
  //         }}
  //       />
  //     )}
  //   </>
  // );
};

export default FileUpload;

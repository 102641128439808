import { Icon, Text } from ".";

import { colors } from "theme/colors";
import { get } from "lodash";
import { getPixels } from "utils/utils";
import { rTranslations } from "utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const Container = styled.div`
  display: flex;
  margin: ${(p) => p.margin || "0px"};
  flex-direction: row;
  width: ${(p) => p.width || "auto"};
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  padding-right: 10px;
  background: ${(p) => p.background};
  border: 1px solid ${(p) => p.borderColor || colors.inputBorder};
`;

const SearchInput = styled.input`
  width: 100%;
  border: 0px;
  background: transparent;
  font-size: 15px;
  color: ${(p) => (p.darkMode ? "white" : colors.grey4)};
  padding: ${(p) => p.padding || "10px"};
  font-weight: 400;
  font-size: 14px;
  outline: 0px;
  &::placeholder {
    color: ${colors.grey3};
  }
`;

const SearchBar = ({ data }) => {
  const {
    width,
    value,
    onChange,
    margin,
    placeholder,
    background = "white",
    borderRadius,
    borderColor,
    //
    clear,
    initialValue,
    submit,
    setupMode,
    padding,
    darkMode,
  } = data;

  const translations = useRecoilValue(rTranslations);

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      submit(value);
    }
  };

  const searchText = get(translations, "searchText", "Search");

  return (
    <Container width={width} margin={margin}>
      <SearchContainer
        background={background}
        borderRadius={borderRadius}
        borderColor={borderColor}
      >
        <SearchInput
          darkMode={darkMode}
          padding={padding}
          placeholder={placeholder || searchText}
          onChange={(e) => {
            e.stopPropagation();

            if (!setupMode) {
              onChange(e.target.value);
            }
          }}
          onKeyPress={(e) => onEnterPress(e)}
          value={value}
        />
        {clear && initialValue && initialValue !== "" && (
          <Text
            data={{
              text: get(translations, "clear", "Clear"),
              fontStyle: "bodyMd",
              color: colors.darkGrey,
            }}
            onClick={clear}
          />
        )}
        {get(value, "length") && !submit ? (
          <Icon
            data={{
              icon: "FiX",
              color: colors.grey3,
              onClick: () => onChange(""),
              hover: true,
            }}
          />
        ) : (
          <Icon
            data={{
              icon: "FiSearch",
              color: colors.grey3,
              onClick: () => !setupMode && submit?.(value, "search"),
              hover: true,
            }}
          />
        )}
      </SearchContainer>
    </Container>
  );
};

export default SearchBar;

import { atom } from "recoil";

export const rUser = atom({
  key: "user",
  default: null,
});

export const rFlow = atom({
  key: "flow",
  default: {},
});

export const rSteps = atom({
  key: "steps",
  default: [],
});

export const rTemplates = atom({
  key: "templates",
  default: [],
});

export const rFlows = atom({
  key: "flows",
  default: [],
});

export const rTranslations = atom({
  key: "translations",
  default: {},
});

export const rOrganization = atom({
  key: "organization",
  default: {},
});

export const rFlowChanges = atom({
  key: "flowChanges",
  default: false,
});

export const rActiveStepId = atom({
  key: "activeStepId",
  default: null,
});

export const rIsFetchingConfig = atom({
  key: "isFetchingConfig",
  default: false,
});

export const rConfirmationModalData = atom({
  key: "confirmationModalData",
  default: null,
});

export const rWebsocketRequests = atom({
  key: "websocketRequests",
  default: [],
});

import { colors } from "theme/colors";
import { getPixels } from "utils/utils";
import styled from "styled-components";

const EditableText = ({
  onChange,
  value = "",
  placeholder = "",
  editable = false,
  fontSize = 16,
  fontWeight = 400,
}) => {
  if (editable) {
    return (
      <StyledInput
        value={value}
        fontSize={fontSize}
        fontWeight={fontWeight}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    );
  }

  return (
    <StyledDiv fontSize={fontSize} fontWeight={fontWeight}>
      {value}
    </StyledDiv>
  );
};

export default EditableText;

const StyledInput = styled.input`
  min-width: 200px;
  width: 100%;
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: ${({ fontSize }) => getPixels(fontSize)};
  font-weight: ${({ fontWeight }) => fontWeight};
  background: transparent;
  &:hover {
    background: ${colors.grey1};
  }
  &:focus {
    outline: none;
  }
`;

const StyledDiv = styled.div`
  font-size: ${({ fontSize }) => getPixels(fontSize)};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

import { Button } from "components";
import { colors } from "theme/colors";
import styled from "styled-components";

export const EmptyState = ({
  title,
  subtitle,
  graphic,
  buttonText,
  onClick,
  margin,
}) => {
  return (
    <CardSection margin={margin}>
      <Graphic src={graphic} />
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Button
          data={{
            text: buttonText,
            size: "large",
            icon: "FiPlus",
            onClick,
          }}
        />
      </div>
    </CardSection>
  );
};

export default EmptyState;

const Graphic = styled.img`
  width: 280px;
  height: 240px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 15px 0;
  max-width: 450px;
`;

const CardSection = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.divider};
  overflow: auto;
  margin: ${(p) => p.margin || "0"};
`;

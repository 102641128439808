import { Icon, Row, Text } from "components";

import { colors } from "theme/colors";
import styled from "styled-components";
import { useState } from "react";

const Tile = ({ label, onClick, icon, onDelete, className }) => {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <TileContainer
      className={className}
      onClick={onClick}
      onMouseEnter={() => {
        if (!showDelete) {
          setShowDelete(true);
        }
      }}
      onMouseLeave={() => {
        if (showDelete) {
          setShowDelete(false);
        }
      }}
    >
      {icon && (
        <Icon
          data={{
            icon: icon,
            color: colors.grey3,
            size: 20,
          }}
        />
      )}
      <Row justifyContent="space-between" alignItems="center" width="100%">
        <Text
          data={{
            cursor: "pointer",
            text: label,
            fontSize: 16,
            fontWeight: 400,
          }}
        />
        {showDelete && onDelete && (
          <Icon
            data={{
              icon: "FiTrash",
              hover: true,
              onClick: (e) => {
                e.stopPropagation();
                onDelete();
              },
              color: colors.grey3,
              size: 18,
            }}
          />
        )}
      </Row>
    </TileContainer>
  );
};

export default Tile;

const TileContainer = styled.div`
  border: 1px solid ${colors.grey2};
  padding: 9px 12px 9px 12px;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  &:hover {
    background: ${colors.grey1};
  }
`;

import * as AdminComponents from "components";
import * as Components from "components";

import FormInputWrapper from "components/FormInputWrapper";
import { get } from "lodash";

const FormField = ({ data }) => {
  const { componentId } = data;
  const FormInput = get(
    { ...Components, ...AdminComponents },
    data.componentId
  );

  if (FormInput) {
    return (
      <FormInputWrapper {...data}>
        {data.skeleton && (
          <Components.SkeletonLoader width="100%" height="38px" />
        )}

        {!data.skeleton && (
          <FormInput
            data={{
              ...data,
              border: data.error ? "1px solid red" : data.border,
            }}
          />
        )}
      </FormInputWrapper>
    );
  }
  // NOT DEFINED
  return <FormInputWrapper {...data}>{componentId}</FormInputWrapper>;
};

export default FormField;

import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import MuiDropdown from "./MuiDropdown";
import { Text } from "components";
import { colors } from "theme/colors";

const Hint = ({ hint, margin, link, width = "400px" }) => {
  return (
    <MuiDropdown
      triggerComponent={
        <HiOutlineQuestionMarkCircle
          size={16}
          color={colors.grey3}
          style={{ margin, cursor: "pointer" }}
        />
      }
    >
      <div style={{ maxWidth: width }}>
        {hint && (
          <Text
            data={{
              text: hint,
              fontStyle: "bodyMd",
              padding: "5px 10px 5px 10px",
            }}
          />
        )}
        {link && (
          <Text
            data={{
              text: "Read the full article",
              fontStyle: "headingSm",
              onClick: () => window.open(link),
              color: colors.primary,
              padding: "5px 10px 5px 10px",
            }}
          />
        )}
      </div>
    </MuiDropdown>
  );
};

export default Hint;

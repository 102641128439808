import { Breadcrumb, Form, FullScreenLoader, Text } from "components";
import { get, isEmpty, isNil, startCase } from "lodash";
import { rActiveStepId, rFlow, rFlowChanges, rSteps } from "utils/recoil";
import { useEffect, useState } from "react";

import Flow from "pages/Flow/Flow";
import Header from "./Header";
import StepSettings from "./StepSettings";
import { apiRequest } from "utils/apiRequests";
import { colors } from "theme/colors";
import { componentIdOptions } from "utils/utils";
import styled from "styled-components";
import useFlow from "hooks/useFlows";
// import HierarchyEditor from "components/HierarchyEditor";
// import StepCard from "./StepCard";
// import { StepContainer } from "./StepContent";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import useSteps from "pages/Flow/useSteps";

const FlowEditor = () => {
  const { id } = useParams();

  const { flow, setFlow } = useFlow();

  const [isFetching, setIsFetching] = useState(false);

  const [steps, setSteps] = useRecoilState(rSteps);

  const flowData = get(flow, "data", {});

  const { addStep, activeStep } = useSteps();

  const [activeStepId, setActiveStepId] = useRecoilState(rActiveStepId);

  // Load flow for edit mode
  useEffect(() => {
    if (id) {
      setIsFetching(true);
      apiRequest
        .get("/flow_details/", {
          params: {
            flow_id: id,
          },
        })
        .then((res) => {
          const response = get(res, "data", {});
          const flowDetails = get(response, "flow", {});
          const steps = get(response, "steps", []);
          setFlow(flowDetails, false);
          setSteps(steps);
          setIsFetching(false);
        });
    }
  }, [id]);

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <Container>
      <Header />
      <BodyRow>
        <Sidebar>
          <SidebarHeader>
            {!activeStepId && (
              <Text
                data={{
                  text: "Flow Settings",
                  fontStyle: "headingMd",
                  fontSize: 18,
                  fontWeight: 600,
                }}
              />
            )}
            {activeStepId && (
              <Breadcrumb
                fontSize={18}
                items={[
                  {
                    text: "Steps",
                    onClick: () => setActiveStepId(null),
                  },
                  {
                    text: get(activeStep, "name", "Step"),
                    onClick: null,
                  },
                ]}
              />
            )}
          </SidebarHeader>
          <SidebarContent>
            {activeStepId && <StepSettings />}
            {!activeStepId && (
              <div style={{ padding: "20px" }}>
                <Form
                  onChange={(k, v) =>
                    setFlow({
                      data: {
                        ...flow.data,
                        [k]: v,
                      },
                    })
                  }
                  fields={[
                    // {
                    //   id: "stepsList",
                    //   label: "Steps",
                    //   name: "steps",
                    //   hint: "Add steps to the flow",
                    //   componentId: "StepsList",
                    // },
                    {
                      id: "intro_step",
                      label: "Add Intro Step",
                      name: "intro_step",
                      hint: "Add an intro step to the flow. If disabled, the flow must be triggered via API",
                      componentId: "Switch",
                      value: get(flowData, "intro_step", false),
                    },
                    {
                      id: "intro_progress_label",
                      label: "Intro Step Label",
                      name: "intro_progress_label",
                      hint: "The label for the intro step in the sidebar",
                      componentId: "Input",
                      value: get(flowData, "intro_progress_label", ""),
                      displayCondition: () =>
                        get(flowData, "intro_step", false),
                    },
                    {
                      id: "intro_title",
                      label: "Intro Step Header",
                      name: "intro_title",
                      hint: "The header for the intro step",
                      componentId: "TextArea",
                      value: get(flowData, "intro_title", ""),
                      displayCondition: () =>
                        get(flowData, "intro_step", false),
                    },
                    {
                      id: "intro_description",
                      label: "Intro Step Description",
                      name: "intro_description",
                      hint: "The description for the intro step",
                      componentId: "TextArea",
                      value: get(flowData, "intro_description", ""),
                      displayCondition: () =>
                        get(flowData, "intro_step", false),
                    },
                    {
                      id: "intro_inputs",
                      label: "Intro Step Inputs",
                      hint: "The starting inputs for this flow",
                      componentId: "MultiForm",
                      orientation: "vertical",
                      labelSingular: "Input",
                      newObject: {
                        key: "new_field",
                        type: "string",
                      },
                      getItemLabel: (item) => item.label || startCase(item.key),
                      value: get(flowData, "intro_inputs", []),
                      displayCondition: () =>
                        get(flowData, "intro_step", false),
                      fields: [
                        {
                          id: "key",
                          label: "Key",
                          componentId: "Input",
                        },
                        {
                          id: "label",
                          label: "Label",
                          componentId: "Input",
                        },
                        {
                          id: "placeholder",
                          label: "Placeholder",
                          componentId: "Input",
                        },
                        {
                          id: "type",
                          label: "Type",
                          componentId: "Select",
                          options: [
                            { label: "String", value: "string" },
                            { label: "Number", value: "number" },
                            { label: "Boolean", value: "boolean" },
                          ],
                        },
                        {
                          id: "componentId",
                          label: "Component",
                          componentId: "Select",
                          options: componentIdOptions,
                        },
                        {
                          id: "options",
                          label: "Options",
                          componentId: "KeyValuePairs",
                          useLabelAsKey: true,
                          displayCondition: (f) =>
                            get(f, "componentId") === "Select",
                        },
                      ],
                    },
                    // {
                    //   id: "hide_progress",
                    //   label: "Hide Progress Sidebar",
                    //   name: "hide_progress",
                    //   hint: "Hide the sidebar",
                    //   componentId: "Switch",
                    //   value: get(flowData, "hide_progress", false),
                    // },
                  ].filter((f) => !f.displayCondition || f.displayCondition())}
                />
                <Text
                  data={{
                    text: "Result Destination",
                    fontStyle: "headingMd",
                    fontSize: 18,
                    fontWeight: 600,
                    margin: "20px 0",
                  }}
                />
                <Form
                  onChange={(k, v) =>
                    setFlow({
                      data: {
                        ...flow.data,
                        [k]: v,
                      },
                    })
                  }
                  fields={[
                    {
                      id: "result_destination",
                      label: "Result Destination",
                      name: "result_destination",
                      hint: "Send the result to an API endpoint",
                      componentId: "Switch",
                      value: get(flowData, "result_destination", false),
                    },
                    {
                      id: "result_destination_url",
                      label: "Result Destination URL",
                      name: "result_destination_url",
                      hint: "Send the result to an API endpoint",
                      componentId: "Input",
                      value: get(flowData, "result_destination_url", ""),
                      displayCondition: () =>
                        get(flowData, "result_destination", false),
                    },
                    // For now making all POST
                    // {
                    //   id: "result_destination_method",
                    //   label: "Result Destination Method",
                    //   name: "result_destination_method",
                    //   hint: "Send the result to an API endpoint",
                    //   componentId: "Select",
                    //   options: [
                    //     { label: "POST", value: "POST" },
                    //     { label: "GET", value: "GET" },
                    //   ],
                    //   value: get(flowData, "result_destination_method", "POST"),
                    //   displayCondition: () =>
                    //     get(flowData, "result_destination", false),
                    // },
                    {
                      id: "result_destination_body",
                      label: "Result Destination Body",
                      name: "result_destination_body",
                      hint: "Send the result to an API endpoint",
                      componentId: "TextArea",
                      value: get(flowData, "result_destination_body", ""),
                      displayCondition: () =>
                        get(flowData, "result_destination", false),
                    },
                    {
                      id: "result_destination_headers",
                      label: "Result Destination Headers",
                      name: "result_destination_headers",
                      hint: "Send the result to an API endpoint",
                      componentId: "KeyValuePairs",
                      value: get(flowData, "result_destination_headers", []),
                      displayCondition: () =>
                        get(flowData, "result_destination", false),
                    },
                  ].filter((f) => !f.displayCondition || f.displayCondition())}
                />
              </div>
            )}
          </SidebarContent>
        </Sidebar>

        <BodyContent>
          {/* {!activeStepId && (
            <StepsContainer>
              <Row>
                {rootSteps.map((r) => (
                  <StepCard
                    key={r.uuid}
                    stepId={r.uuid}
                    steps={steps}
                    conditionWarning={rootConditionsWarning}
                  />
                ))}
              </Row>

              {steps.length === 0 && (
                <StepContainer
                  onClick={() => addStep({ parent: null })}
                  style={{ width: "fit-content" }}
                >
                  <Row
                    justifyContent="space-between"
                    alignItems="center"
                    gap="10px"
                  >
                    <Icon
                      data={{
                        icon: "FiPlus",
                        color: colors.grey3,
                        hover: true,
                      }}
                    />
                    <Text
                      data={{
                        text: "Add Step",
                        fontStyle: "headingMd",
                        cursor: "pointer",
                      }}
                    />
                  </Row>
                </StepContainer>
              )}
            </StepsContainer>
          )} */}
          {/* {activeStepId && <Flow />} */}
          <Flow isEditor={true} />
        </BodyContent>
      </BodyRow>
    </Container>
  );
};

export default FlowEditor;

const BodyContent = styled.div`
  padding: 30px;
  display: flex;
  gap: 30px;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
`;

const BodyRow = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Sidebar = styled.div`
  min-width: 420px;
  height: 100%;
  border-right: 1px solid ${colors.divider};
  background: white;
`;

const SidebarHeader = styled.div`
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid ${colors.divider};
`;

const SidebarContent = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

// const StepsContainer = styled.div`
//   padding: 30px;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   width: 100%;
// `;

// const steps = get(flow, "steps", []).filter((s) => !s.__deleted);

// const rootSteps = steps.filter((s) => !s.parent);

// const rootStepsWithoutConditions = rootSteps.filter(
//   (s) => !get(s, "conditions")
// );
// const rootConditionsWarning =
//   rootSteps.length > 1 && rootStepsWithoutConditions.length > 0;

// const schema = get(activeStep, "schema", {});

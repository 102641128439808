import * as React from "react";

import { Icon } from "components";
import { colors } from "theme/colors";
import styled from "styled-components";

const Progress = ({ orientation = "vertical", steps, activeIndex }) => {
  const finalOrientation =
    orientation === "responsive"
      ? window.innerWidth < 768
        ? "horizontal"
        : "vertical"
      : orientation;

  return (
    <Container orientation={finalOrientation}>
      {steps.map((step, index) => {
        const active = index <= activeIndex;
        return (
          <>
            <Step
              key={index}
              isLast={index === steps.length - 1}
              lineHighlight={index < activeIndex}
              active={active}
              data={step}
              orientation={finalOrientation}
            />
          </>
        );
      })}
    </Container>
  );
};

export default Progress;

const Step = ({
  data,
  active,
  isLast,
  orientation,
  lineHighlight,
  hideTextHorizontal,
}) => {
  const { label, description } = data;

  const primaryColor = colors.primary;

  return (
    <StepContainer orientation={orientation}>
      {!isLast && (
        <Line
          color={lineHighlight ? primaryColor : colors.grey3}
          orientation={orientation}
        />
      )}
      <div style={{ minWidth: "20px", zIndex: 100 }}>
        <Circle active={active} />
      </div>
      {!hideTextHorizontal && (
        <div>
          <Label color={active ? primaryColor : colors.darkGrey}>{label}</Label>
          {description && (
            <Description color={active ? primaryColor : colors.darkGrey}>
              {description}
            </Description>
          )}
        </div>
      )}
    </StepContainer>
  );
};

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: transparent;
  border: ${(p) => (p.active ? "8px" : "3px")} solid
    ${(p) => (p.active ? colors.primary : colors.grey3)};
`;

const Line = styled.div`
  position: absolute;
  background-color: ${(p) => p.color};
  z-index: 90;
  ${(p) =>
    p.orientation === "vertical"
      ? `
      width: 2px;
      top: 16px;
      bottom: 0px;
      left: 11px;
    `
      : `
      height: 2px;
      left: calc(50% + 11px);
      right: calc(-50% + 11px);
      bottom: 14px;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.orientation === "vertical" ? "column" : "row")};
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${(p) => p.color};
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${(p) => p.color};
`;

const StepContainer = styled.div`
  display: flex;
  position: relative;
  ${(p) =>
    p.orientation === "vertical"
      ? `
      padding-bottom: 25px;
      gap: 15px;
    `
      : `
      flex-direction: column-reverse;
      align-items: center;
      gap: 5px;
      flex: 1;
      text-align: center;
    `}
`;

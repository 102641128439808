import { Fragment, useState } from "react";
import { Icon, Spinner } from ".";
import { getCurrentDomain, getPixels } from "utils/utils";
import { rOrganization, rTranslations, rUser } from "utils/recoil";

import { apiRequest } from "utils/apiRequests";
import { colors } from "theme/colors";
import { get } from "lodash";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

export const getCloudinaryWidget = ({
  activeApp,
  translations,
  formats,
  user,
  stateSet,
  onAssetUploaded,
  onChange,
}) => {
  const cloudName =
    get(activeApp, "cloudinary_cloud_name", "frontly") || "frontly";
  const preset =
    get(activeApp, "cloudinary_preset", "ml_default") || "ml_default";
  const uploadPreset = cloudName === "frontly" ? "frontly_resize" : preset;

  const maxFileSize = get(activeApp, "cloudinary_max_file_size", 5) || 5;
  const maxFileSizeInBytes = maxFileSize * 1000000;

  const uploadWidget = window.cloudinary.createUploadWidget(
    {
      cloudName,
      uploadPreset,
      context: { subdomain: getCurrentDomain() },
      sources: ["local", "url", "camera"],
      clientAllowedFormats: formats,
      maxFileSize: maxFileSizeInBytes,
      multiple: false,
      language: "en",
      text: {
        en: {
          or: get(translations, "uploadOr", "Or"),
          camera: {
            capture: get(translations, "uploadCameraCapture", "Capture"),
            cancel: get(translations, "uploadCameraCancel", "Cancel"),
            take_pic: get(
              translations,
              "uploadCameraTakePicture",
              "Take a picture and upload it"
            ),
            explanation: get(
              translations,
              "uploadCameraExplanation",
              "Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture."
            ),
            camera_error: get(
              translations,
              "uploadCameraError",
              "Failed to access camera"
            ),
            retry: get(translations, "uploadRetryCamera", "Retry Camera"),
            file_name: `${get(
              translations,
              "uploadCamera",
              "Camera"
            )}_{{time}}`,
          },
          menu: {
            files: get(translations, "uploadFiles", "My Files"),
            web: get(translations, "uploadWebAddress", "Web Address"),
            camera: get(translations, "uploadCamera", "Camera"),
          },
          local: {
            browse: get(translations, "uploadBrowse", "Browse"),
            dd_title_single: get(
              translations,
              "uploadTitleSingle",
              "Drag and Drop an asset here"
            ),
            dd_title_multi: get(
              translations,
              "uploadTitleMulti",
              "Drag and Drop assets here"
            ),
            drop_title_single: get(
              translations,
              "uploadDropTitleSingle",
              "Drop a file to upload"
            ),
            drop_title_multiple: get(
              translations,
              "uploadDropTitleMulti",
              "Drop files to upload"
            ),
          },
          url: {
            inner_title: get(
              translations,
              "uploadUrlTitle",
              "Public URL of file to upload:"
            ),
          },
        },
      },
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        let newImageUrl = result.info.secure_url;
        // TODO - Save as assets, but race condition I think

        if (newImageUrl.includes(".heic")) {
          newImageUrl = newImageUrl.replace(".heic", ".png");
        }
        if (newImageUrl.includes(".HEIC")) {
          newImageUrl = newImageUrl.replace(".HEIC", ".png");
        }

        if (["owner", "admin"].includes(get(user, "role"))) {
          saveAsset(result, onAssetUploaded);
        }

        if (onChange) {
          setTimeout(() => onChange(newImageUrl), 500);
        }
      }
      stateSet((oldState) => ({ ...oldState, isFetching: false }));
    }
  );

  return uploadWidget;
};

const Image = styled.img`
  height: 50px;
  width: 110px;
  object-fit: cover;
  object-fit: contain;
  border-radius: ${getPixels(4)};
`;

const ImageBorder = styled.div`
  height: 60px;
  width: 120px;
  background: ${(p) => p.background || "white"};
  border-radius: ${getPixels(4)};
  padding: 4px;
  border: ${(p) => p.border || `1px solid ${colors.inputBorder}`};
`;

const ImageFrame = styled.div`
  height: 60px;
  width: 120px;
  display: flex;
  ${(p) => (p.disabled ? "background: lightgrey;" : "background: white;")}
  border: 1px solid ${colors.grey2};
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${getPixels(4)};
  ${(p) => p.error && "border: 1px solid red;"}
`;

const RelativeContainer = styled.div`
  position: relative;
  height: 70px;
  width: 120px;
`;

const FloatingButton = styled.div`
  display: flex;
  background-color: ${(p) =>
    p.darkMode ? colors.darkModeLightBackground : "white"};
  position: absolute;
  border-radius: 50%;
  ${(p) => (p.position === "top" ? "top: -9px;" : "bottom: -9px;")}
  border: 1px solid ${(p) =>
    p.darkMode ? colors.darkModeLightBorder : colors.grey2};
  right: -9px;
  -webkit-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.17);
  padding: 4px;
`;

// TODO - probably check this flow because I think there's a race condition

export const saveAsset = (result, onAssetUploaded = null) => {
  const info = get(result, "info");
  const format = get(info, "format");
  const bytes = get(info, "bytes");
  const secureUrl = get(info, "secure_url");
  const externalId = get(info, "asset_id");

  const asset = {
    url: secureUrl,
    file_format: format,
    file_size: bytes,
    external_id: externalId,
  };

  apiRequest.post("/assets/", asset).then((response) => {
    const newAsset = get(response, "data");
    if (onAssetUploaded) {
      onAssetUploaded(newAsset);
    }
  });
};

const ImageUpload = ({ data }) => {
  const [hover, setHover] = useState(false);

  const [state, stateSet] = useState({
    isFetching: false,
    settings: {},
    errors: {},
  });

  const translations = useRecoilValue(rTranslations);

  const user = useRecoilValue(rUser);
  const activeApp = useRecoilValue(rOrganization);

  const showWidget = () => {
    stateSet((oldState) => ({ ...oldState, isFetching: true }));

    const uploadWidget = getCloudinaryWidget({
      activeApp,
      translations,
      formats: ["png", "svg", "jpg", "jpeg", "pdf", "webp", "heic"],
      user,
      stateSet,
      onAssetUploaded: data.onAssetUploaded,
      onChange: data.onChange,
    });

    uploadWidget.open();
  };

  return (
    <div
      onMouseEnter={() => {
        if (!hover) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (hover) {
          setHover(false);
        }
      }}
    >
      {state.isFetching ? (
        <ImageFrame darkTheme={data.darkTheme}>
          <Spinner size="small" color={colors.primary} />
        </ImageFrame>
      ) : (
        <Fragment>
          <RelativeContainer>
            {data.value ? (
              <ImageBorder background={data.background} border={data.border}>
                <Image src={data.value} />
              </ImageBorder>
            ) : (
              <ImageFrame
                darkTheme={data.darkTheme}
                error={data.error}
                disabled={data?.disabled}
              >
                <Icon
                  data={{
                    icon: "FiImage",
                    color: colors.grey3,
                    size: "34px",
                  }}
                />
              </ImageFrame>
            )}
            {hover && !data?.disabled && (
              <FloatingButton
                position="top"
                onClick={showWidget}
                darkMode={data.darkMode}
              >
                <Icon
                  data={{
                    icon: "FiEdit2",
                    color: colors.grey3,
                    size: "16px",
                    hover: true,
                  }}
                />
              </FloatingButton>
            )}
            {hover && data.value && !data?.disabled && (
              <FloatingButton
                position="bottom"
                darkMode={data.darkMode}
                onClick={() => data.onChange("")}
              >
                <Icon
                  data={{
                    icon: "FiX",
                    color: colors.grey3,
                    size: "16px",
                    hover: true,
                  }}
                />
              </FloatingButton>
            )}
          </RelativeContainer>
        </Fragment>
      )}
    </div>
  );
};

export default ImageUpload;

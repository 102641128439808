import {
  Button,
  CardGrid,
  FullScreenLoader,
  Modal,
  Row,
  SearchBar,
} from "components";
import { get, startCase } from "lodash";
import { rFlows, rTemplates } from "utils/recoil";
import { useEffect, useState } from "react";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import { safeLower } from "utils/utils";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const Templates = ({ itemsPerTemplate = 12 }) => {
  const [templates, setTemplates] = useRecoilState(rTemplates);

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [flows, setFlows] = useRecoilState(rFlows);

  const filteredTemplates = templates.filter(
    (p) =>
      search === "" ||
      safeLower(p.name).includes(safeLower(search)) ||
      safeLower(p.url).includes(safeLower(search))
  );

  // Fetch templates
  useEffect(() => {
    if (templates.length === 0) {
      setIsFetching(true);
      apiRequest.get("/templates/").then((r) => {
        setTemplates(get(r, "data", []));
        setIsFetching(false);
      });
    }
  }, []);

  const installTemplate = (templateId) => {
    apiRequest
      .post("/templates/", {
        template_id: templateId,
      })
      .then((r) => {
        const newData = get(r, "data", {});

        setFlows([...flows, newData]);

        successNotification("Template installed");

        navigate(`/flow/edit/${newData.uuid}`);
      });
  };

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <AdminWrapper>
      {showDetailsModal && (
        <Modal
          header={{
            title: "Template Details",
          }}
          hide={() => setShowDetailsModal(false)}
        >
          DETAILS
        </Modal>
      )}

      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Templates</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search templates",
              onChange: (v) => setSearch(v),
            }}
          />
          {/* <Button
            data={{
              text: "Generate With AI",
              icon: "FiPlus",
              onClick: () => setShowNewTemplateModal(true),
            }}
          /> */}
        </Row>
      </Row>

      <CardGrid
        cardWidth="300px"
        noResultsName={"templates"}
        items={filteredTemplates.map((t) => ({
          title: t.name,
          description: t.description,
          onClick: () => installTemplate(t.id),
          badges: get(t, "tags", []).map((x) => ({
            color: "#AFE6F1",
            text: startCase(x),
          })),
        }))}
        itemsPerTemplate={itemsPerTemplate}
      />
    </AdminWrapper>
  );
};

export default Templates;

const Label = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

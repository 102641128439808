import { Button, Text } from "components";

import boxShadow from "theme/boxShadow";
import { colors } from "theme/colors";
import { get } from "lodash";
import styled from "styled-components";

const CardWrapper = styled.div`
  background: white;
  box-shadow: ${boxShadow.card};
  margin: ${(p) => p.margin};
  border-radius: 10px;
  width: ${(p) => p.width || "fit-content"};
  ${(p) => p.stretchHeight && "align-self: stretch;"}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 10px 16px;
`;

const Content = styled.div`
  padding: ${(p) => p.padding};
`;

const Card = ({
  children,
  header,
  width,
  margin,
  padding = "15px",
  onClick,
  stretchHeight = false,
}) => (
  <CardWrapper
    width={width}
    margin={margin}
    onClick={onClick}
    stretchHeight={stretchHeight}
  >
    {header && <Header data={header} />}
    <Content padding={padding}>{children}</Content>
  </CardWrapper>
);

export default Card;

const Header = ({ data }) => {
  const { label, description } = data;

  const buttons = get(data, "buttons", []);

  return (
    <HeaderContainer>
      <div>
        {label && <Text data={{ text: label, fontStyle: "headingMd" }} />}
        {description && (
          <Text
            data={{
              text: description,
              color: colors.grey3,
              fontStyle: "bodySm",
              margin: "3px 0 0 0",
            }}
          />
        )}
      </div>
      {buttons.map((b, i) => (
        <Button key={i} data={b} />
      ))}
    </HeaderContainer>
  );
};

import { Button, CardGrid, Row, SearchBar } from "components";
import { rConfirmationModalData, rFlows } from "utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import AdminWrapper from "components/AdminWrapper";
import EmptyState from "components/EmptyState";
import NewFlowModal from "./NewFlowModal";
import { apiRequest } from "utils/apiRequests";
import boxShadow from "theme/boxShadow";
import { colors } from "theme/colors";
import { get } from "lodash";
import { safeLower } from "utils/utils";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Flows = ({ itemsPerFlow = 12 }) => {
  const [flows, setFlows] = useRecoilState(rFlows);

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [showNewFlowModal, setShowNewFlowModal] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const deleteFlow = (flowId) => {
    const newFlows = flows.filter((p) => p.id !== flowId);
    setFlows(newFlows);
    successNotification("Flow Deleted");

    apiRequest.delete("/flow_details/", {
      data: { id: flowId },
    });
  };

  const filteredFlows = flows.filter(
    (p) =>
      search === "" ||
      safeLower(p.name).includes(safeLower(search)) ||
      safeLower(p.url).includes(safeLower(search))
  );

  return (
    <AdminWrapper>
      {showNewFlowModal && (
        <NewFlowModal hide={() => setShowNewFlowModal(false)} />
      )}
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Flows</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search flows",
              onChange: (v) => setSearch(v),
            }}
          />
          <Button
            data={{
              text: "New Flow",
              icon: "FiPlus",
              onClick: () => setShowNewFlowModal(true),
            }}
          />
        </Row>
      </Row>

      {flows.length === 0 && (
        <EmptyState
          title="You don't have any flows"
          subtitle="Flows are the basic building blocks of your app, where you can show content. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716244997/Create_ljrvzs.svg"
          }
          buttonText="New Flow"
          onClick={() => setShowNewFlowModal(true)}
        />
      )}

      {flows.length > 0 && (
        <CardGrid
          noResultsName={"flows"}
          items={filteredFlows.map((f) => ({
            title: f.name,
            description: f.url,
            onClick: () => navigate(`/flow/edit/${f.uuid}`),
            // badges: [
            //   {
            //     color: "#AFE6F1",
            //     text: `${get(f, ["steps", "length"])} steps`,
            //   },
            // ],
            options: [
              // {
              //   label: "View Live Flow",
              //   icon: "FiExternalLink",
              //   onClick: () => {
              //     const token = Cookies.get("accessToken");
              //     openFlowInNewTab({ flow: p, app, token });
              //   },
              // },
              // {
              //   label: "Duplicate Flow",
              //   icon: "FiCopy",
              //   onClick: () => {
              //     apiRequest
              //       .post("/duplicate_flow/", { id: f.id })
              //       .then((r) => {
              //         const newFlow = get(r, "data");
              //         setFlows([...flows, newFlow]);
              //         successNotification("Flow Duplicated");
              //       });
              //   },
              // },
              {
                label: "Delete",
                icon: "FiTrash",
                onClick: () =>
                  setConfirmationModalData({
                    title: "Delete Flow",
                    text: "Are you sure you want to delete this flow? This is irreversible.",
                    confirm: () => deleteFlow(f.id),
                  }),
              },
            ],
          }))}
          itemsPerFlow={itemsPerFlow}
        />
      )}
    </AdminWrapper>
  );
};

export default Flows;

export const Label = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const Container = styled.div`
  border-radius: 10px;
  box-shadow: ${boxShadow.card};
  background: white;
  overflow: hidden;
  margin-top: 20px;
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Header = styled.div`
  padding: 15px;
`;

export const Badge = styled.div`
  background: ${(p) => p.color};
  padding: 5px 7px 5px 7px;
  border-radius: 16px;
  font-size: 11px;
  font-weight: 400;
  color: #202223;
  width: fit-content;
`;

export const CardLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const CardText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${colors.grey3};
  margin-top: 4px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 15px;
  cursor: pointer;
  gap: 20px;
  border: 1px solid ${colors.grey1};
  border-radius: 12px;
  &:hover {
    background: ${colors.grey1};
  }
`;

export const CardSection = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.divider};
  overflow: auto;
`;

import { Button, FullScreenLoader, Row, Text } from "components";
import { rActiveStepId, rFlow, rSteps } from "utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import Header from "./Header";
import HierarchyEditor from "components/HierarchyEditor";
import { apiRequest } from "utils/apiRequests";
import { colors } from "theme/colors";
import { convertToSimplifiedData } from "utils/utils";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import useAdmin from "hooks/useAdmin";
import useFlow from "hooks/useFlows";
import useSteps from "pages/Flow/useSteps";

const Flow = ({ isEditor = false }) => {
  const { flow } = useFlow();

  const { isAdmin } = useAdmin();

  const { addStep, steps } = useSteps();

  const setSteps = useSetRecoilState(rSteps);

  const { id, sessionId } = useParams();

  const [activeStepId, setActiveStepId] = useRecoilState(rActiveStepId);
  const setFlow = useSetRecoilState(rFlow);

  const [session, setSession] = useState({});

  // Handle both edit mode and live mode
  const currentStepId = activeStepId || get(session, "current_step") || null;

  const [sessionData, setSessionData] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Removing for now since we're going to avoid nesting short-term
  // const rootSteps = steps.filter((s) => !s.parent);

  const matchingStep = steps.find((s) => {
    if (!isAdmin && currentStepId === "intro") {
      return s.uuid === null;
    }

    return s.uuid === currentStepId;
  });

  const schema = get(matchingStep, "schema", []);

  const navigate = useNavigate();

  const stepSessionData = isEditor
    ? convertToSimplifiedData(schema)
    : get(sessionData, currentStepId, {});

  // Load flow in live mode
  useEffect(() => {
    if (!isEditor) {
      setIsFetching(true);
      apiRequest
        .get("/session_details/", {
          params: {
            flow_id: id,
            session_id: sessionId,
          },
        })
        .then((res) => {
          const f = get(res, ["data", "flow"], {});
          const fSteps = get(res, ["data", "steps"], []);

          const s = get(res, ["data", "session"], {});

          const sessionUuid = get(s, "uuid", "");

          const currentSessionData = get(s, "data", {});

          setFlow(f);
          setSteps(fSteps);
          setSession(s);

          setSessionData(currentSessionData);

          if (sessionUuid && !sessionId) {
            navigate(`/flow/${id}/${sessionUuid}`);
          }

          setIsFetching(false);
        });
    }
  }, []);

  const headerDescription = get(matchingStep, "description", "");

  const hideProgress = get(flow, ["data", "hide_progress"], false);

  const isLastStep =
    steps.findIndex((s) => s.uuid === currentStepId) === steps.length - 1;

  const submit = (moveToNextStep = false) => {
    if (moveToNextStep) {
      setIsSubmitting(true);
    } else {
      setIsSaving(true);
    }

    apiRequest
      .post("/step/", {
        flow_id: id,
        session_id: sessionId,
        step_id: currentStepId,
        data: stepSessionData,
        next_step: moveToNextStep,
        is_last_step: isLastStep,
      })
      .then((res) => {
        const responseData = get(res, "data");

        const session = get(responseData, "session", {});

        const sessionUuid = get(session, "uuid", {});
        const sessionData = get(session, "data", {});

        if (sessionUuid) {
          navigate(`/flow/${id}/${sessionUuid}`);
        }

        const nextStep = get(responseData, "next_step", {});
        if (nextStep) {
          setSession({
            ...session,
            current_step: nextStep,
          });
        }

        setSessionData(sessionData);

        if (!moveToNextStep) {
          successNotification("Changes saved");
          setIsSaving(false);
        } else {
          setIsSubmitting(false);
        }
      });
  };

  const [sidebarHover, setSidebarHover] = useState(false);

  const mouseEvents = isAdmin
    ? {
        onMouseEnter: () => setSidebarHover(true),
        onMouseLeave: () => setSidebarHover(false),
      }
    : {};

  //
  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <Container isAdmin={isAdmin}>
      <Header />
      <BodyRow>
        {!hideProgress && (
          <Sidebar {...mouseEvents}>
            {steps.map((s, i) => (
              <StepContainer
                key={s.uuid}
                active={
                  currentStepId === "intro"
                    ? s.uuid === null
                    : s.uuid === currentStepId
                }
                onClick={() => (isAdmin ? setActiveStepId(s.uuid) : null)}
              >
                {i + 1}. {s.name}
              </StepContainer>
            ))}
            {sidebarHover && (
              <Button
                data={{
                  text: "Add Step",
                  icon: "FiPlus",
                  type: "basic",
                  margin: "10px 0 0 0",
                  width: "100%",
                  onClick: () => {
                    const lastStep = steps[steps.length - 1];
                    addStep({ parent: lastStep.uuid });
                  },
                }}
              />
            )}
          </Sidebar>
        )}
        <Content>
          <InnerContent isAdmin={isAdmin}>
            <div>
              <Text
                data={{
                  text: get(matchingStep, "title", ""),
                  fontSize: 26,
                  fontWeight: 500,
                }}
              />
              {headerDescription && (
                <Text
                  data={{
                    text: headerDescription,
                    fontSize: 18,
                    fontWeight: 300,
                    color: colors.grey3,
                    margin: "10px 0 0 0",
                  }}
                />
              )}
            </div>
            <HierarchyEditor
              data={stepSessionData}
              schema={schema}
              onChange={(newValue) => {
                setSessionData({
                  ...sessionData,
                  [currentStepId]: newValue,
                });
              }}
            />
          </InnerContent>
          <Footer>
            <Button
              data={{
                text: "Previous Step",
                icon: "FiArrowLeft",
                type: "basic",
                onClick: () => console.log("PREVIOUS"),
              }}
            />
            <Row gap="15px">
              <Button
                data={{
                  text: "Save Changes",
                  type: "basic",
                  isFetching: isSaving,
                  onClick: () => submit(false),
                }}
              />
              {isLastStep && (
                <Button
                  data={{
                    text: "Confirm & Submit",
                    onClick: () => submit(false),
                    // icon: "FiArrowRight",
                    isFetching: isSubmitting,
                    flippedIcon: true,
                    disabled: isSaving || isSubmitting,
                  }}
                />
              )}
              {!isLastStep && (
                <Button
                  data={{
                    text: "Next Step",
                    onClick: () => submit(true),
                    icon: "FiArrowRight",
                    isFetching: isSubmitting,
                    flippedIcon: true,
                    disabled: isSaving || isSubmitting,
                  }}
                />
              )}
            </Row>
          </Footer>
        </Content>
      </BodyRow>
    </Container>
  );
};

export default Flow;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.divider};
  border-radius: 10px;
  background: white;
  width: 100%;
  height: calc(100vh - 120px);
  ${(p) => !p.isAdmin && `height: calc(100vh);`}
`;

const BodyRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Content = styled.div`
  width: 100%;
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 255px);
  ${(p) => !p.isAdmin && `height: calc(100vh - 130px);`}
`;

const StepContainer = styled.div`
  padding: 15px;
  ${(p) => p.active && `background: #E0EEFF; color: ${colors.primary};`}
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
`;

const Sidebar = styled.div`
  min-width: 260px;
  border-right: 1px solid ${colors.divider};
  padding: 20px;
  height: 100%;
`;

const Footer = styled.div`
  padding: 15px 20px 15px 20px;
  background: white;
  border-top: 1px solid ${colors.divider};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import MDEditor from "@uiw/react-md-editor";
import React from "react";
import styled from "styled-components";

const MarkdownEditor = ({ data }) => {
  const { value, onChange } = data;

  return (
    <Container data-color-mode="light">
      <MDEditor
        value={value}
        onChange={onChange}
        previewProps={{
          enablePreview: false,
        }}
      />
    </Container>
  );
};

export default MarkdownEditor;

const Container = styled.div`
  white-space: pre-wrap;
`;

import { Button, Examples, Form, Row, Text } from "components";

import Hierarchy from "components/Hierarchy";
import { colors } from "theme/colors";
import { get } from "lodash";
import { rConfirmationModalData } from "utils/recoil";
import { safeArray } from "utils/utils";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import useFlow from "hooks/useFlows";
import { useSetRecoilState } from "recoil";
import useSteps from "pages/Flow/useSteps";

const StepSettings = () => {
  const { flow } = useFlow();

  const { deleteStep, updateStep, activeStep: step, steps } = useSteps();

  const children = steps.filter(
    (step) => step.parent && step.parent === step.uuid
  );
  const childCount = children.length;

  const schema = get(step, "schema", []);

  // const otherFields = [
  //   {
  //     id: "context",
  //     label: "Context File",
  //     hint: "Other context for the LLM to consider",
  //     componentId: "FileUpload",
  //     orientation: "vertical",
  //   },
  //   TODO - Add Conditions later
  //   {
  //     id: "conditions",
  //     sectionHint:
  //       "Set conditions to determine whether this step and any of it's children will be run or not.",
  //     componentId: "DisplayConditions",
  //     orientation: "vertical",
  //     section: "Run Conditions",
  //   },
  // ];

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const confirmDelete = () => {
    if (childCount > 0) {
      setConfirmationModalData({
        title: "Delete Action Step?",
        text: "This step has depdendencies. Any references to this step in subsequent steps will need to be manually updated.",
        confirm: () => {
          successNotification("Step Deleted");
          deleteStep(step.uuid);
        },
      });
    } else {
      setConfirmationModalData({
        title: "Delete Action Step?",
        text: "This is irreversible. Are you sure you want to continue?",
        confirm: () => {
          successNotification("Step Deleted");
          deleteStep(step.uuid);
        },
      });
    }
  };

  const flowInputs = get(flow, ["data", "intro_inputs"], []).map((v) => v.key);

  // Think about this
  // const getPreviousStepsRecursively = (currentStep) => {
  //   const parentUuid = get(currentStep, "parent");
  //   if (!parentUuid) {
  //     return [];
  //   }
  //   const parentStep = steps.find((s) => s.uuid === parentUuid);
  //   return [parentStep, ...getPreviousStepsRecursively(parentStep)];
  // };

  // const previousSteps = getPreviousStepsRecursively(step);
  // console.log(previousSteps);

  // Presumably at some point there will be more variables
  const variables = ["input", ...flowInputs];

  return (
    <SectionsContainer>
      <Section>
        <Text
          data={{
            text: "Basics",
            fontSize: 18,
            fontWeight: 500,
            margin: "0 0 20px 0",
          }}
        />
        <Form
          key={step.id}
          sectionPadding={"0px 0px 15px 0px"}
          gridLayout
          onChange={(k, v) => updateStep(k, v, step.uuid)}
          fields={[
            {
              id: "name",
              label: "Step Name",
              hint: "The name of the step",
              componentId: "Input",
              orientation: "vertical",
              value: get(step, "name"),
            },
            {
              id: "title",
              label: "Header Text",
              hint: "The text displayed as a header",
              componentId: "Input",
              orientation: "vertical",
              value: get(step, "title", ""),
            },
            {
              id: "description",
              label: "Header Description",
              hint: "Secondary text displayed below the header text",
              componentId: "Input",
              orientation: "vertical",
              value: get(step, "description", ""),
            },
          ]}
        />
        <Form
          key={step.uuid}
          sectionPadding={"0px 0px 15px 0px"}
          gridLayout
          margin="15px 0 0 0"
          onChange={(k, v) => updateStep(k, v, step.uuid)}
          fields={[
            {
              id: "prompt",
              label: "Prompt",
              hint: "The prompt sent to the LLM",
              componentId: "TextArea",
              orientation: "vertical",
              minHeight: "100px",
              value: get(step, "prompt", ""),
            },
          ]}
        />
        <Text
          data={{
            text: "Available Input Variables",
            fontSize: 14,
            fontWeight: 500,
            margin: "15px 0 7px 0",
          }}
        />
        <Row gap="5px">
          {variables.map((v) => (
            <Variable>{v}</Variable>
          ))}
        </Row>

        <Text
          data={{
            text: "Delete Step",
            fontSize: 14,
            fontWeight: 500,
            margin: "15px 0 7px 0",
          }}
        />
        <Button
          data={{
            text: "Delete Step",
            type: "basic",
            icon: "FiTrash",
            onClick: confirmDelete,
          }}
        />
      </Section>

      <Section>
        <Text
          data={{
            text: "Output",
            fontSize: 18,
            fontWeight: 500,
            margin: "0 0 20px 0",
          }}
        />
        <Hierarchy
          data={schema}
          onChange={(v) => {
            updateStep("schema", v, step.uuid);
          }}
        />
      </Section>

      {/* <Section>
        <Text
          data={{
            text: "Training Examples",
            fontSize: 18,
            fontWeight: 500,
            margin: "0 0 20px 0",
          }}
        />
        <Examples
          data={{
            onChange: (v) => {
              updateStep("examples", v, step.uuid);
            },
            value: get(step, "examples", []),
          }}
        />
      </Section> */}
    </SectionsContainer>
  );
};

export default StepSettings;

const Variable = styled.div`
  background: ${colors.primary};
  padding: 5px;
  border-radius: 6px;
  width: fit-content;
  color: white;
  font-weight: 600;
  font-size: 12px;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Section = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.divider};
  flex: ${(props) => props.flex || "1"};
  background: white;
  width: 100%;
`;

import { Column } from ".";
import Spinner from "./Spinner";
import { colors } from "theme/colors";
import styled from "styled-components";

const FullScreenLoader = () => {
  return (
    <Loader>
      <Column gap="30px" alignItems="center" justifyContent="center">
        {/* {logoUrl && <Logo src={logoUrl} />} */}
        <Spinner color={colors.grey4} size={40} />
      </Column>
    </Loader>
  );
};

export default FullScreenLoader;

const Logo = styled.img`
  height: 50px;
`;

const Loader = styled.div`
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// import { HelpCard, HelpCardsContainer } from "./HelpCard";
import { Icon, PopupMenu, Spinner } from "components";
import { rOrganization, rUser } from "utils/recoil";
import { useLocation, useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import { addSlash } from "utils/utils";
import { colors } from "theme/colors";
import curatorLogo from "../assets/curator-logo-blue.png";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import { useRecoilValue } from "recoil";

const AdminWrapper = ({
  children,
  contentWidth = null,
  padding = "40px",
  isFetching = false,
}) => {
  const location = useLocation();

  const path = get(location, "pathname");

  const user = useRecoilValue(rUser);

  const organization = useRecoilValue(rOrganization);

  const subscription = get(organization, "subscription");

  //   const currentPlan = get(subscription, "plan");

  const helpCards = [
    {
      title: "YouTube",
      description: "Watch video tutorials and guides on our YouTube channel.",
      onClick: () => window.open("https://www.youtube.com/@frontlyapp"),
      linkText: "Go to YouTube",
      icon: "FiYoutube",
    },
    {
      title: "Help Articles",
      description: "Browse our extensive collection of help articles.",
      onClick: () => window.open("https://help.frontly.ai"),
      linkText: "Read Help Articles",
      icon: "FiBook",
    },
    // {
    //   title: "Experts Marketplace",
    //   description: "Find and hire experts for your projects.",
    //   onClick: () => navigate("/experts-marketplace"),
    //   linkText: "Explore Experts Marketplace",
    //   icon: "FiUsers",
    // },
    {
      title: "Discord Community",
      description: "Join 1,400+ users for discussion, support and inspiration.",
      onClick: () => window.open("https://discord.gg/8wnQndpBGU"),
      linkText: "Visit Community Forum",
      icon: "FiMessageCircle",
    },
  ];

  const community = [
    // {
    //   icon: "FiLifeBuoy",
    //   label: "Resources",
    //   onClick: () => setShowHelp(true),
    // },
  ];

  let homeSection = [
    // {
    //   icon: "FiHome",
    //   label: "Home",
    //   route: "/home",
    // },
    {
      icon: "FiGitPullRequest",
      label: "Flows",
      route: "/flows",
    },
    {
      icon: "FiGrid",
      label: "Templates",
      route: "/templates",
    },
    {
      icon: "FiUsers",
      label: "Users",
      route: "/users",
    },
    {
      icon: "FiSettings",
      label: "Settings",
      route: "/settings",
    },
  ];

  const navigate = useNavigate();

  let popupLinks = [
    {
      text: "Assets",
      icon: "FiImage",
      onClick: () => navigate("/assets"),
    },
    {
      text: "Billing",
      icon: "FiCreditCard",
      onClick: () => navigate("/billing"),
    },
    // {
    //   text: "Refer Users",
    //   icon: "BsMegaphone",
    //   onClick: () =>
    //     window.open("https://referrals.frontly.ai/signup?campaign=referrals"),
    // },
    {
      text: "Logout",
      icon: "FiLogOut",
      onClick: () => {
        // clearConfig();
        // TODO - Clear config in recoil
        Cookies.remove("accessToken");
        localStorage.removeItem("appId");
        successNotification("Logged out.");
        navigate("/login");
      },
    },
  ];

  //   const [showHelp, setShowHelp] = useState(false);

  return (
    <Container>
      {/* {showHelp && (
        <Modal
          minWidth="650px"
          hide={() => setShowHelp(false)}
          header={{
            title: "Need help? We've got you covered",
          }}
        >
          <HelpCardsContainer>
            {helpCards.map((card) => (
              <HelpCard data={card} />
            ))}
          </HelpCardsContainer>
        </Modal>
      )} */}
      <Body>
        <Sidebar>
          <LogoContainer>
            <Logo src={curatorLogo} />
          </LogoContainer>

          {/* <Row
            alignItems="center"
            gap="5px"
            style={{
              padding: "12px",
              borderBottom: `1px solid ${colors.grey15}`,
            }}
          >
            <Select
              data={{
                hideEmptyItem: true,
                width: "171px",
                borderRadius: "10px",
                value: app.id,
                options: apps.map((a) => ({ label: a.name, value: a.id })),
                onChange: (v) => switchApp(v),
              }}
            />
            <Button
              data={{
                onClick: () => window.open(getAppLink(app)),
                type: "basic",
                icon: "FiExternalLink",
                height: "39px",
              }}
            />
          </Row> */}

          <SplitContent>
            <div>
              <ItemsList>
                {homeSection.map((item) => {
                  const isActive = addSlash(path).includes(
                    addSlash(item.route)
                  );
                  return <Item item={item} active={isActive} />;
                })}
              </ItemsList>

              {/* <SectionLabel>Resources</SectionLabel> */}
              {/* <ItemsList>
                {community.map((item) => {
                  const isActive = addSlash(path).includes(
                    addSlash(item.route)
                  );
                  return <Item item={item} active={isActive} />;
                })}
              </ItemsList> */}
            </div>

            <BottomSectionContainer>
              {/* {!currentPlan && !path.includes("billing") && (
                <UpgradeButton onClick={() => navigate("/billing")}>
                  <div>Upgrade To Publish</div>
                  <Icon data={{ icon: "FiZap", size: 20, hover: true }} />
                </UpgradeButton>
              )} */}

              <PopupMenuWrapper>
                <PopupMenu
                  data={{
                    backgroundColor: "#9a9db3",
                    textColor: "white",
                    width: "160px",
                    activeUser: user,
                    links: popupLinks,
                  }}
                />
              </PopupMenuWrapper>
            </BottomSectionContainer>
          </SplitContent>
        </Sidebar>
        <Content>
          <ContentWidth width={contentWidth} padding={padding}>
            {isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <Spinner size={40} />
              </div>
            ) : (
              children
            )}
          </ContentWidth>
        </Content>
      </Body>
    </Container>
  );
};

const UpgradeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: ${colors.primary};
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
  margin-bottom: 20px;
  &:hover {
    filter: brightness(1.1);
  }
`;

const BottomSectionContainer = styled.div``;

const PopupMenuWrapper = styled.div`
  margin: 0 0 5px 0;
  padding: 8px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid ${colors.divider};
  &:hover {
    background: ${colors.clickUpBackground};
  }
`;

const Item = ({ item, active }) => {
  const navigate = useNavigate();

  // Get onClick
  let onClick = null;
  if (item.onClick) {
    onClick = item.onClick;
  } else if (item.link) {
    onClick = () => window.open(item.link);
  } else if (item.route) {
    onClick = () => navigate(item.route);
  }

  return (
    <ItemContainer active={active} onClick={onClick}>
      <Icon
        data={{
          icon: item.icon,
          size: 20,
          color: active ? colors.primary : colors.default,
        }}
      />
      <ItemLabel active={active}>{item.label}</ItemLabel>
      {item.badge && (
        <div
          style={{
            background: colors.primary,
            color: "white",
            borderRadius: "4px",
            padding: "2px 4px 2px 4px",
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          {item.badge}
        </div>
      )}
    </ItemContainer>
  );
};

export default AdminWrapper;

const SplitContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding: 12px;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Sidebar = styled.div`
  align-items: flex-start;
  min-width: 240px;
  overflow-y: auto;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e0e1e6;
  z-index: 9999;
`;

const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
`;

const ContentWidth = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${(p) => p.padding};
  flex: 1;
`;

const LogoContainer = styled.div`
  border-bottom: 1px solid ${colors.grey15};
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
`;

const Logo = styled.img`
  height: 26px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 11px 11px 11px 11px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  ${(p) =>
    p.active
      ? `
        background: ${colors.primaryUltraLight};
      `
      : `
    &:hover {
      background: rgb(238, 238, 240, 0.5);
    }
  `};
`;

const ItemLabel = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: ${(p) => (p.active ? colors.primary : colors.default)};
`;

const SectionLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.grey3};
  padding: 0 22px 5px 11px;
  margin-top: 15px;
`;

import { addSlash } from "utils/utils";
import { get } from "lodash";
import { useLocation } from "react-router-dom";

const publicRoutes = ["/login", "/signup", "/flow/"];
const adminRoutes = [
  "/flow/edit",
  "/flows",
  "/users",
  "/settings",
  "/templates",
  "/billing",
  "/assets",
];

// check if path includes any of the routes
const includesAny = (path, routes) =>
  routes.some((route) => path.includes(route));

// TODO - if we offer subdomains, then we'll need to use this
// ["app.curatorapp.ai"].includes(getCurrentDomain());

const useAdmin = () => {
  const location = useLocation();
  const path = get(location, "pathname");

  const isAdmin = includesAny(addSlash(path), adminRoutes);
  const isPublic =
    !isAdmin && (path === "/" || includesAny(addSlash(path), publicRoutes));

  return { isAdmin, isPublic };
};

export default useAdmin;

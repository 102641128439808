import { get, toString, truncate } from "lodash";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { colors } from "theme/colors";
import { getPixels } from "utils/utils";
import { rTranslations } from "utils/recoil";
import { useRecoilValue } from "recoil";

const MultiSelect = ({ data }) => {
  const translations = useRecoilValue(rTranslations);
  const options = get(data, "options", []).map((o) => ({
    ...o,
    value: toString(o.value),
  }));

  let value = get(data, "value") || get(data, "defaultValue") || "";

  // Split the comma separated string values
  const splitValues = value ? value.split(",").map((req) => req.trim()) : [];

  const selectText = get(translations, "selectText", "Select");

  let backgroundColor = data.backgroundColor || data.background || "white";

  return (
    <div style={{ width: data.width || "auto" }}>
      <style>{`
          .MuiAutocomplete-inputRoot {
            border: ${
              data.border || `1px solid ${colors.inputBorder}`
            } !important;
            background-color: ${backgroundColor} !important;
            border-radius: ${getPixels(data.borderRadius || 8)} !important;
            color: ${data.color};
            height: 39px;
            padding: 0 0 0 10px !important;
            margin: 0px !important;
            font-size: 20px !important;
            font-weight: 500 !important;
          }
        `}</style>

      <Autocomplete
        disabled={data.disabled}
        multiple
        value={splitValues}
        options={options.map((o) => o.value)}
        getOptionLabel={(option) => {
          return get(
            options.find((o) => o.value === option),
            "label",
            null
          );
        }}
        disableCloseOnSelect
        onChange={(event, newValue) => {
          data.onChange(newValue.join(", "));
        }}
        renderTags={() => {
          if (splitValues.length > 1) {
            return `${splitValues.length} Selections`;
          } else {
            const matchingOption = options.find((o) => o.value === value);
            return truncate(get(matchingOption, "label"), { length: 20 });
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              sx={{
                "& fieldset": {
                  border: "0px",
                },
              }}
              placeholder={
                splitValues.length > 0 ? "" : `${selectText} ${data.label}`
              }
            />
          );
        }}
      />
    </div>
  );
};

export default MultiSelect;

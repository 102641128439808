import { rFlow, rFlowChanges } from "utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

const useFlow = () => {
  const [flow, setFlowRecoil] = useRecoilState(rFlow);

  const setFlowChanges = useSetRecoilState(rFlowChanges);

  const setFlow = (data, setChanges = true) => {
    setFlowChanges(setChanges);
    setFlowRecoil({
      ...flow,
      ...data,
    });
  };

  return { flow, setFlow };
};

export default useFlow;

import { Button, Hint, Row, Text } from "components";
import { get, startCase } from "lodash";
import { getPixels, getUniqueValues, sortSections } from "utils/utils";

import FormField from "components/FormField";
import { colors } from "theme/colors";
import styled from "styled-components";

const Form = ({
  value,
  fields,
  onChange,
  submit,
  submitText,
  isFetching,
  buttonColor = colors.primary,
  sectionOrder = [],
  errors,
  buttonFullWidth,
  borderBottom = null,
  margin = "0px",
  disableSubmit,
  buttons = [],
  submitOnEnter = false,
}) => {
  const sections = sortSections(
    getUniqueValues(fields, "section"),
    sectionOrder
  );

  const hideBorder = sections.length === 1;

  const getComponent = (componentId, disabled) => {
    if (
      !disabled ||
      ["Switch", "Checkbox", "ImageUpload", "TextArea"].includes(componentId)
    ) {
      return componentId;
    }

    if (!["Switch", "Checkbox", "ImageUpload"].includes(componentId)) {
      return "Input";
    }
  };

  fields = fields.map((f) => ({
    ...f,
    componentId: getComponent(f.componentId, f.disabled),
    value: f.value || get(value, f.id),
  }));

  return (
    <div style={{ margin, width: "100%" }}>
      {sections.map((s, index) => {
        const sectionFields = fields.filter((f) => f.section === s);

        const sectionHint = sectionFields.find((f) => f.sectionHint);

        return (
          <FormSection
            key={index}
            label={startCase(s)}
            hint={sectionHint && sectionHint.sectionHint}
            borderBottom={borderBottom && !hideBorder}
          >
            {sectionFields.map((field, i) => {
              let fieldOnChange = get(field, "onChange") || onChange;

              return (
                <FormField
                  key={field.id}
                  data={{
                    ...field,
                    componentId: get(field, "componentId", "Input"),
                    error: get(errors, field.id),
                    onChange: (value) =>
                      fieldOnChange(field.id || field.key, value),
                    onKeyPress: (e) => {
                      if (submitOnEnter) {
                        if (e.key === "Enter") {
                          submit();
                        }
                      }
                    },
                  }}
                />
              );
            })}
          </FormSection>
        );
      })}

      {(submit || buttons.length > 0) && (
        <Row alignItems="center" gap="15px" margin="20px 0 0 0">
          {submit && (
            <Button
              data={{
                backgroundColor: buttonColor,
                onClick: submit,
                width: buttonFullWidth && "100%",
                isFetching,
                text: submitText || "Submit",
                disabled: disableSubmit,
                fontStyle: "headingSm",
              }}
            />
          )}
          {buttons && buttons.map((b) => <Button data={b} />)}
        </Row>
      )}
    </div>
  );
};

export default Form;

const Fields = styled.div`
  border-bottom: ${(p) => (p.borderBottom ? "1px" : "0px")} solid
    ${colors.inputBorder};
  padding: ${(p) => p.sectionPadding || "15px"};
  display: flex;
  flex-direction: column;
  gap: ${(p) => getPixels(p.gap || 15)};
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
`;

export const FormSection = ({ label, hint, borderBottom, children }) => {
  return (
    <Fields
      borderBottom={borderBottom}
      sectionPadding={"0px"}
      gap={hint ? 5 : 15}
    >
      {label && (
        <Row alignItems="center" gap="5px">
          <Text
            data={{
              text: label,
              fontSize: 14,
              fontWeight: 500,
              cursor: "pointer",
              color: "#40424f",
            }}
          />
          {hint && <Hint hint={hint} margin="5px 0 0 0" />}
        </Row>
      )}
      <Items>{children}</Items>
    </Fields>
  );
};

import { getPixels, safeParseFloatOrInt } from "utils/utils";

import { colors } from "theme/colors";
import styled from "styled-components";

const Input = ({ data }) => {
  const { onChange } = data;

  const validTypes = ["text", "number", "password", "email"];

  let backgroundColor = data.backgroundColor || data.background;
  return (
    <StyledInput
      onKeyDown={(e) => e.stopPropagation()}
      fontSize={data.fontSize || 14}
      fontWeight={data.fontWeight || 400}
      {...data}
      type={validTypes.includes(data.type) ? data.type : "text"}
      background={backgroundColor}
      width={data.width || "100%"}
      autoComplete="new-password"
      onChange={(e) => {
        let newValue = e.target.value;
        // If the input is a number and has a max value, make sure it doesn't exceed the max value
        if (data.type === "number" && data.max) {
          if (safeParseFloatOrInt(newValue) > data.max) {
            newValue = data.max;
          }
        }

        onChange(newValue);
      }}
    />
  );
};

export default Input;

const StyledInput = styled.input`
  padding: ${(p) => p.padding || "10px"};
  border: ${(p) => p.border || `1px solid ${colors.inputBorder}`};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight};
  background: ${(p) => p.background};
  color: ${(p) => p.color};
  width: ${(p) => p.width};
  text-align: left;
  min-width: 50px;
  &:focus {
    outline: none;
  }

  ${(p) =>
    p.placeholderColor &&
    `
  ::placeholder {
    color: ${p.placeholderColor};
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
  color: ${p.placeholderColor};
  }
  `}
`;

import {
  cloneDeep,
  get,
  isArray,
  isInteger,
  isNaN,
  isNil,
  isString,
  startsWith,
} from "lodash";

export const componentIdOptions = [
  { label: "Input", value: "Input" },
  {
    label: "Editable Label",
    value: "Label",
  },
  {
    label: "Text",
    value: "Text",
  },
  { label: "TextArea", value: "TextArea" },
  { label: "Dropdown", value: "Select" },
  { label: "Switch", value: "Switch" },
  {
    label: "Markdown Editor",
    value: "MarkdownEditor",
  },
];

export const fieldTypeToComponentIdMap = {
  string: "Input",
  number: "Input",
  boolean: "Checkbox",
  date: "DatePicker",
  datetime: "DateTimePicker",
  time: "TimePicker",
};

export const convertToSimplifiedData = (schema) => {
  const isAdmin = true;
  const simplified = {};

  schema.forEach((item) => {
    const key = get(item, "key", "");

    const defaultString = ""; // isAdmin ? "example" : "";
    const defaultNumber = isAdmin ? 123 : "";
    const defaultBoolean = isAdmin ? true : "";
    const defaultStringArray = isAdmin
      ? ["example 1", "example 2", "example 3"]
      : [];
    const defaultNumberArray = isAdmin ? [123, 456, 789] : [];

    const itemType = get(item, "type", "string");

    if (itemType === "string") {
      simplified[key] = defaultString;
    } else if (itemType === "number") {
      simplified[key] = defaultNumber;
    } else if (itemType === "boolean") {
      simplified[key] = defaultBoolean;
    } else if (itemType === "array") {
      const arrayType = get(item, "array_type", "string");
      if (arrayType === "string") {
        simplified[key] = defaultStringArray;
      } else if (arrayType === "number") {
        simplified[key] = defaultNumberArray;
      } else if (arrayType === "object") {
        simplified[key] = [convertToSimplifiedData(get(item, "keys", []))];
      }
    } else if (itemType === "object") {
      simplified[key] = convertToSimplifiedData(get(item, "keys", []));
    }
  });

  return simplified;
};

export const getType = (value) => {
  if (typeof value === "string") {
    // if (isValidDate(value)) {
    //   return "date";
    // } else {
    // }
    return "string";
  } else if (typeof value === "number" && Number.isInteger(value)) {
    return "integer";
  } else if (typeof value === "number") {
    return "number";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "object") {
    return "object";
  }
};

export const getDateFormatString = (format) => {
  const formatType = get(format, "formatType");
  const customFormat =
    formatType === "custom" ? get(format, "customFormat") : null;

  if (customFormat) {
    return customFormat;
  }

  const outputTime = get(format, "outputTime");
  const inputTime = get(format, "inputTime");
  const inputDate = get(format, "inputDate");
  const showAmPm = get(format, "showAmPm");

  let formatString = "";

  if (inputDate && inputTime && outputTime !== "none") {
    formatString = `${inputDate} ${inputTime}`;
  } else if (inputDate) {
    formatString = inputDate;
  } else if (inputTime && outputTime !== "none") {
    formatString = inputTime;
  }

  if (!showAmPm) {
    // If not showing AM/PM, convert to 24 hour format
    formatString = formatString.replace("hh", "HH").replace("h", "HH");
  }

  return formatString;
};

export const publicRoutes = ["/login", "/signup"];

export function getPreviousSteps(actions, actionId) {
  // Create a map for easy access to each action by its ID
  const actionMap = new Map(actions.map((action) => [action.id, action]));

  // Function to get all ancestors of a given action
  function getAncestors(actionId) {
    const action = actionMap.get(actionId);
    if (!action || !action.parent) {
      return [];
    }
    // Get the parent and recursively get its ancestors
    const parentAncestors = getAncestors(action.parent);
    return [action.parent, ...parentAncestors];
  }

  return getAncestors(actionId);
}

export const isValidJson = (inputJson) => {
  try {
    JSON.parse(inputJson);
    return true;
  } catch (error) {
    return false;
  }
};

export const operatorLabelMap = {
  // Equals
  equals: "Equals",
  does_not_equal: "Does Not Equal",
  contains: "Contains",
  in: "In",
  not_in: "Not In",
  does_not_contain: "Does Not Contain",
  // Less / more
  greater_than: "Greater Than",
  less_than: "Less Than",
  length_greater_than: "Length Greater Than",
  length_less_than: "Length Less Than",
  // Booleans
  is_true: "Is True (boolean)",
  is_false: "Is False (boolean)",
  exists: "Exists (has value)",
  does_not_exist: "Does Not Exist (no value)",
  // Dates
  date_equals: "Date Equals",
  date_after: "Date After",
  date_before: "Date Before",
  date_in_range: "Date In Range",
};

export const operators = [
  "equals",
  "does_not_equal",
  "greater_than",
  "less_than",
  "contains",
  "in",
  "not_in",
  "is_true",
  "is_false",
  "does_not_contain",
  "exists",
  "does_not_exist",

  // "length_less_than",
  // "length_greater_than",
  // "date_equals",
  // "date_after",
  // "date_before",
  // "date_in_range",
];

export function formatNumber(num, decimals) {
  if (isNaN(decimals)) {
    return num;
  }

  const decimalsInt = parseInt(decimals, 0);

  if (!decimalsInt || decimalsInt === 0) {
    return num;
  }

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);
}

export const lightenColor = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const safeString = (str) => {
  if (str === null || str === undefined) {
    return "";
  }

  return str.toString();
};

export const safeToFixed = (value, decimals) => {
  if (!value) {
    const num = 0;
    return num.toFixed(decimals);
  }

  try {
    return parseFloat(value).toFixed(decimals);
  } catch (error) {
    const num = 0;
    return num.toFixed(decimals);
  }
};

export const safeArray = (obj, key = null) => {
  let potentialArray = obj;

  if (key) {
    potentialArray = get(obj, key, []);
  }

  if (isArray(potentialArray)) {
    return potentialArray;
  }

  return [];
};

export const parseNumber = (value) => {
  if (value) {
    // Remove commas, spaces, and any currency symbols
    const cleanedValue = value.toString().replace(/[,€£$¥₹%\s]/g, "");

    // Check if cleaned value contains a float
    if (/^-?\d+\.\d+$/.test(cleanedValue)) {
      return parseFloat(cleanedValue);
    }

    // Check if cleaned value contains an integer
    if (/^-?\d+$/.test(cleanedValue)) {
      return parseInt(cleanedValue, 10);
    }

    // If value contains numbers mixed with letters or is invalid, return 0
    return 0;
  }
  return 0;
};

export const safeLower = (str) => {
  if (typeof str === "string") {
    return str.toLowerCase();
  }

  if (isNil(str)) {
    return "";
  }

  return str.toString();
};

export function safeParseFloatOrInt(str) {
  const parsedFloat = parseFloat(str);
  const parsedInt = parseInt(str, 10);

  // Check if the parsedFloat is a valid number (not NaN) and the same as the parsedInt
  if (!isNaN(parsedFloat) && parsedFloat === parsedInt) {
    return parsedInt; // Return the parsed integer if it's a valid integer
  } else if (!isNaN(parsedFloat)) {
    return parsedFloat; // Return the parsed float if it's a valid float
  } else {
    return 0; // Return 0 if neither a valid float nor integer
  }
}

export const addTransparency = (hex = "") => {
  if (!hex) {
    return hex;
  }

  const { length } = hex;
  const hasTransparency = length === 9;
  if (hasTransparency) {
    return hex;
  } else {
    return hex + "ff";
  }
};

const getArrayFromCommaSeparatedString = (s) => {
  const split = s
    ? s
        .toString()
        .split(",")
        .map((v) => v.trim())
        .filter((s) => s !== "")
    : [];
  return split;
};

export const passesCondition = (data) => {
  const {
    value1 = "",
    value2 = "",
    operator = "contains",
    value1DateFormat = null,
    // value2DateFormat = null,
  } = data;

  let meetsCondition = false;

  // EXISTS
  if (operator === "exists") {
    meetsCondition = !isNil(value1) && value1 !== "";
  }

  // DOES NOT EXIST
  else if (operator === "does_not_exist") {
    meetsCondition = isNil(value1) || value1 === "";
  }

  // IS TRUE
  else if (operator === "is_true") {
    meetsCondition = parseBoolean(value1);
  }

  // IS FALSE
  else if (operator === "is_false") {
    meetsCondition = !parseBoolean(value1);
  }

  // EQUALS
  else if (operator === "equals") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    // COMPARE 2 COMMA-SEPARATED LISTS
    let matches = 0;
    value1Array.forEach((v1) => {
      value2Array.forEach((v2) => {
        if (safeLower(v1) === safeLower(v2)) {
          matches += 1;
        }
      });
    });

    meetsCondition = matches > 0;
  }

  // DOES NOT EQUAL
  else if (operator === "does_not_equal") {
    meetsCondition = safeLower(value1) !== safeLower(value2);
  }

  // STRING EQUALS STRING
  else if (operator === "string_equals_string") {
    meetsCondition = safeLower(value1) === safeLower(value2);
  }

  // IN
  else if (operator === "in" || operator === "string_in_array") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value1Array.length === 0 || value2Array.length === 0) {
      return false;
    }

    meetsCondition = value1Array.some((val1) =>
      value2Array.includes(safeLower(val1))
    );
  }

  // STRING IN ARRAY
  else if (operator === "string_in_string") {
    meetsCondition = safeLower(value2).includes(safeLower(value1));
  }

  // STRING NOT IN STRING
  else if (operator === "string_not_in_string") {
    meetsCondition = !safeLower(value2).includes(safeLower(value1));
  }

  // NOT IN
  else if (operator === "string_not_in_array") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value1Array.length === 0 || value2Array.length === 0) {
      return false;
    }

    meetsCondition = !value1Array.some((val1) =>
      value2Array.includes(safeLower(val1))
    );
  }

  // CONTAINS
  else if (operator === "contains" || operator === "array_contains_string") {
    if (value1.includes(",") || value2.includes(",")) {
      const value1Array = getArrayFromCommaSeparatedString(value1);
      const value2Array = getArrayFromCommaSeparatedString(value2);

      if (value1Array.length === 0 || value2Array.length === 0) {
        return false;
      }

      // Check if any element in value2Array exists in value1Array
      meetsCondition = value2Array.some((val2) =>
        value1Array.includes(safeLower(val2))
      );
    } else {
      meetsCondition = safeLower(value1).includes(safeLower(value2));
    }
  }

  // STRING CONTAINS STRING
  else if (operator === "string_contains_string") {
    meetsCondition = safeLower(value1).includes(safeLower(value2));
  }

  // DOES NOT CONTAIN
  else if (
    operator === "does_not_contain" ||
    operator === "string_does_not_contain_string"
  ) {
    meetsCondition = !safeLower(value1).includes(safeLower(value2));
  }

  // ARRAY DOES NOT CONTAIN STRING
  else if (operator === "array_does_not_contain_string") {
    const value1Array = getArrayFromCommaSeparatedString(value1);
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value1Array.length === 0 || value2Array.length === 0) {
      return false;
    }

    meetsCondition = !value2Array.some((val2) =>
      value1Array.includes(safeLower(val2))
    );
  }

  // LENGTH GREATER THAN
  else if (operator === "length_greater_than") {
    meetsCondition = safeLower(value1).length > Number(value2);
  }

  // LENGTH LESS THAN
  else if (operator === "length_less_than") {
    meetsCondition = safeLower(value1).length < Number(value2);
  }

  // GREATER THAN
  else if (operator === "greater_than") {
    meetsCondition = Number(value1) > Number(value2);
  }

  // LESS THAN
  else if (operator === "less_than") {
    meetsCondition = Number(value1) < Number(value2);
  }

  // // DATE EQUALS
  // else if (operator === "date_equals") {
  //   const date1 = parseDateWithFormatObject({
  //     value: value1,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });

  //   // Hard-coding the match the date format of the datepicker component
  //   const date2 = parseDateWithFormatObject({
  //     value: value2,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });

  //   if (date1 && date2) {
  //     meetsCondition = date1.isSame(date2, "day");
  //   } else {
  //     meetsCondition = false;
  //   }
  // }

  // // DATE AFTER
  // else if (operator === "date_after") {
  //   const date1 = parseDateWithFormatObject({
  //     value: value1,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });
  //   const date2 = parseDateWithFormatObject({
  //     value: value2,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });

  //   if (date1 && date2) {
  //     meetsCondition = date1.isAfter(date2);
  //   } else {
  //     meetsCondition = false;
  //   }
  // }

  // // DATE BEFORE
  // else if (operator === "date_before") {
  //   const date1 = parseDateWithFormatObject({
  //     value: value1,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });
  //   const date2 = parseDateWithFormatObject({
  //     value: value2,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });

  //   if (date1 && date2) {
  //     meetsCondition = date1.isBefore(date2);
  //   } else {
  //     meetsCondition = false;
  //   }
  // }

  // // DATE RANGE
  // else if (operator === "date_in_range") {
  //   const date1 = parseDateWithFormatObject({
  //     value: value1,
  //     formatObject: value1DateFormat,
  //     returnMoment: true,
  //   });
  //   const [start, end] = value2.split("|");

  //   if (start && end) {
  //     const rangeStart = parseDateWithFormatObject({
  //       value: start,
  //       formatObject: value1DateFormat,
  //       returnMoment: true,
  //     });

  //     const rangeEnd = parseDateWithFormatObject({
  //       value: end,
  //       formatObject: value1DateFormat,
  //       returnMoment: true,
  //     });

  //     if (date1 && rangeStart && rangeEnd) {
  //       meetsCondition = date1.isBetween(rangeStart, rangeEnd, "day", "[]");
  //     } else {
  //       meetsCondition = true;
  //     }
  //   }
  // }

  // NUMBER IN RANGE
  else if (operator === "number_in_range") {
    const [min, max] = value2.split("|");

    if (!min && !max) {
      return true;
    } else if (min && max) {
      meetsCondition =
        Number(value1) >= Number(min) && Number(value1) <= Number(max);
    } else if (min) {
      meetsCondition = Number(value1) >= Number(min);
    } else if (max) {
      meetsCondition = Number(value1) <= Number(max);
    }
  }

  return meetsCondition;
};

export const getHighest = (source, field = "id") => {
  let highestId = 0;

  if (!source) {
    return highestId;
  }

  safeArray(source).forEach((f) => {
    const match = get(f, field) && parseInt(get(f, field));
    if (match && match > highestId) {
      highestId = match;
    }
  });
  return highestId;
};

export const getGridStaticItems = (count, size = "300px") => {
  if (count === 1) {
    return size;
  } else if (count === 2) {
    return `${size} ${size}`;
  } else if (count === 3) {
    return `${size} ${size} ${size}`;
  }
};

export const sortSections = (sections, sectionOrder) => {
  const orderedSections = sections
    .filter((section) => sectionOrder.includes(section))
    .sort((a, b) => sectionOrder.indexOf(a) - sectionOrder.indexOf(b));

  const undefinedSections = sections.filter(
    (section) => !sectionOrder.includes(section)
  );

  return orderedSections.concat(undefinedSections);
};

export const getUniqueValues = (array, key) => {
  if (!isArray(array)) {
    return [];
  }

  let uniqueValues = array.reduce((result, item) => {
    if (result.indexOf(item[key]) === -1) {
      result.push(get(item, key));
    }
    return result;
  }, []);

  return uniqueValues;
};

export const getCurrentDomain = () => {
  let url = window.location.href;

  if (url.includes("localhost")) {
    url = "http://app.curatorapp.ai";
  }

  const splitURL = url
    .replace("https://", "")
    .replace("http://", "")
    .split("/");

  const domain = splitURL[0].toLowerCase();

  return domain;
};

export const arrayMove = (arrayItems, removedIndex, addedIndex) => {
  const arrCopy = cloneDeep(arrayItems);
  const removedItem = arrCopy.splice(removedIndex, 1);
  arrCopy.splice(addedIndex, 0, removedItem[0]);

  return arrCopy;
};

export const addSlash = (route) => {
  if (!route) {
    return null;
  }
  if (startsWith(route, "/") || route.includes("http")) {
    return route;
  }
  return `/${route}`;
};

export const getPixels = (number) => {
  if (number === 0 || number === "0") {
    return "0px";
  }
  // VIEWHEIGHT STRING
  if (isString(number) && number.endsWith("vh")) {
    return number;
  }
  // PERCENT STRING
  if (isString(number) && number.endsWith("%")) {
    return number;
  }
  // PIXEL STRING
  if (isString(number) && number.includes("px")) {
    return number;
  }
  // INTEGER
  if (parseInt(number)) {
    return `${parseInt(number)}px`;
  }
  return number;
};

export function validURL(str) {
  return str.includes("http");
}

export const getValueType = (value) => {
  let valueType = typeof value;

  if (Array.isArray(value)) {
    valueType = "array";
  } else if (value === "") {
    valueType = "empty string";
  } else if (value === null) {
    valueType = "null";
  }
  return valueType;
};

export const parseBoolean = (value) => {
  if (typeof value === "boolean") return value;

  if (typeof value === "string") {
    const trimmedValue = value.trim().toLowerCase();

    switch (trimmedValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false;
    }
  }

  return false;
};

export const getUrlParameter = (name, location) => {
  if (!name) {
    return null;
  }

  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  let value =
    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  } else if (isInteger(value)) {
    return parseInt(value);
  }

  return value;
};

import Cookies from "js-cookie";
import axios from "axios";
import { get } from "lodash";

export const baseApi = process.env.REACT_APP_API_URL;
export const webUrl = process.env.REACT_APP_WEB_URL;

export const getApiRequestHeaders = () => {
  let url = window.location.href;

  let headersObj = {
    Authorization: `Token ${Cookies.get("accessToken")}`,
    "Content-Type": "application/json",
  };

  return headersObj;
};

export const handleError = (e) => {
  const response = get(e, "response", {});
  const status = get(response, "status");
  const message = get(response, ["data", "error"]);
  return { status, message };
};

export const apiRequest = axios.create({
  baseURL: baseApi,
  timeout: 20000,
});

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response?.status === 401) {
    // Add your logic to
    //  1. Redirect user to LOGIN
    //  2. Reset authentication from localstorage/sessionstorage

    Cookies.remove("accessToken");
    localStorage.removeItem("appId");
    window.location.href = "/";
  }
  return Promise.reject(error);
};

apiRequest.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

apiRequest.interceptors.request.use(
  (config) => {
    config.headers = getApiRequestHeaders();
    // Add domain to every request so we know which app we're dealing with
    config.params = {
      // Add domain here if we add domains to our system
      ...config.params,
    };

    // Optionally pass an abort signal through the config if it exists
    if (config.abortSignal) {
      config.signal = config.abortSignal;
      delete config.abortSignal; // Remove it to clean up the config object
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiRequestWithCustomHeaders = (
  customHeaders = [],
  addAuthToken = true
) => {
  const requestInstanceForConfig = axios.create({
    timeout: 20000,
  });

  requestInstanceForConfig.interceptors.request.use(
    (config) => {
      let headers = {
        // TODO - Add this as an option to each api config whether in-line or api configs page
        "Content-Type": "application/json",
      };

      if (addAuthToken) {
        headers["Authorization"] = `Token ${Cookies.get("accessToken")}`;
      }

      customHeaders.forEach((header) => {
        headers[header.key] = header.value;
      });

      config.headers = headers;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return requestInstanceForConfig;
};

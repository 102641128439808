import { Breadcrumb, Button, EditableText, Icon, Text } from "components";

import Modal from "@mui/material/Modal";
import { colors } from "theme/colors";
import { getPixels } from "utils/utils";
import styled from "styled-components";
import useAdmin from "hooks/useAdmin";

const Content = styled.div`
  padding: ${(p) => p.padding};
  overflow-y: auto;
  max-height: ${(p) => p.maxHeight};
  border-radius: 0 0 12px 12px;
`;

const Footer = styled.div`
  border-top: 1px solid ${colors.grey2};
  padding: 15px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  gap: 13px;
`;

const ModalContainer = styled.div`
  background: ${(p) => p.background || "white"};
  border-radius: ${(p) => p.borderRadius};
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => p.maxHeight};
  &:focus-visible {
    outline: none;
  }

  @media screen and (max-width: 800px) {
    min-width: calc(100% - 20px);
  }
`;

const SimpleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  background: ${(p) => p.background};
  border-radius: 12px 12px 0 0;
`;

const XSpacer = styled.div`
  width: 25px;
`;

const FullScreenModal = ({
  children,
  hide,
  header = {},
  background = "white",
  contentPadding = "0px 20px 20px 20px",
  minWidth = "600px",
  buttons = [],
  borderRadius = "12px",
  darkMode = false,
}) => {
  const { isAdmin } = useAdmin();

  // Header params
  const { title, description, titleOnChange, breadcrumbs } = header;

  const renderTitle = () => {
    if (breadcrumbs) {
      return <Breadcrumb items={breadcrumbs} />;
    }

    return (
      <EditableText
        editable={!!titleOnChange}
        fontSize="20"
        fontWeight="500"
        value={title}
        placeholder={"Step Name"}
        onChange={(v) => titleOnChange(v)}
      />
    );
  };

  const verticalPadding = isAdmin ? 150 : 40;

  const showFooter = buttons.length > 0;
  const footerHeight = showFooter ? 70 : 0;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const maxHeight = getPixels(windowHeight - verticalPadding);
  const contentMaxHeight = getPixels(
    windowHeight - verticalPadding - 100 - footerHeight
  );

  const modalProps = {
    open: true,
    onClose: hide,
    style: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const maxWidthNum = windowWidth - 60;

  const resolvedMinWidth =
    parseInt(minWidth) > maxWidthNum ? getPixels(maxWidthNum) : minWidth;

  const modalContent = (
    <ModalContainer
      borderRadius={borderRadius}
      minWidth={
        parseInt(minWidth) > maxWidthNum ? getPixels(maxWidthNum) : minWidth
      }
      maxWidth={resolvedMinWidth}
      maxHeight={maxHeight}
      background={background}
    >
      <SimpleHeader background={background}>
        <div>
          {renderTitle()}
          <Text
            data={{
              text: description,
              fontStyle: "bodyLg",
              margin: "10px 0 10px 0",
              color: colors.grey4,
            }}
          />
        </div>

        <XSpacer>
          <Icon
            data={{
              icon: "FiX",
              onClick: hide,
              color: darkMode ? colors.darkModeLightGrey : colors.medGrey,
              hover: true,
              size: 24,
            }}
          />
        </XSpacer>
      </SimpleHeader>

      <Content
        background={background}
        padding={contentPadding}
        maxHeight={contentMaxHeight}
      >
        {children}
      </Content>

      {showFooter && (
        <Footer>
          {buttons.map((b, i) => (
            <Button data={b} />
          ))}
        </Footer>
      )}
    </ModalContainer>
  );

  // FULL MODAL
  return <Modal {...modalProps}>{modalContent}</Modal>;
};

export default FullScreenModal;

import { DataGrid } from "components";
import { get } from "lodash";

const KeyValuePairs = ({ data }) => {
  const filters = get(data, "value", []);

  const addFilter = (newFilters) => {
    data.onChange(newFilters);
  };

  return (
    <DataGrid
      data={{
        excludedSources: get(data, "excludedSources", []),
        dynamicSources: get(data, "dynamicSources", []),
        previousSteps: get(data, "previousSteps"),
        newObject: {},
        onChange: (newFilters) => addFilter(newFilters),
        value: filters,
        columns: [
          {
            key: data.useLabelAsKey ? "label" : "key",
            label: data.useLabelAsKey ? "Label" : "Key",
            width: "100%",
            componentId: "Input",
            value: get(data, data.useLabelAsKey ? "label" : "key"),
          },
          {
            key: "value",
            label: data.valueLabel || "Value",
            width: "100%",
            componentId: "Input",
            value: get(data, "value"),
          },
        ],
      }}
    />
  );
};

export default KeyValuePairs;

import { Button, Form, Menu, Tile } from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getHighest, safeArray } from "utils/utils";

import { get } from "lodash";
import { useState } from "react";

// THIS IS A GENERIC MULTI-ADD COMPONENT ALLOWING KEYS
// TO BE PASSED IN AND CONFIGURED IN A POPUP MENU

const MultiForm = ({ data }) => {
  const { onChange } = data;

  const [newItems, setNewItems] = useState([]);

  const items = safeArray(get(data, "value", []));
  const fields = get(data, "fields", []);

  const labelSingular = data.labelSingular || "Item";

  const addItem = () => {
    const newId = getHighest(items, "id") + 1;
    let newObj = {
      ...get(data, "newObject", {}),
    };

    if (!data.noLabel) {
      newObj["label"] = `New ${labelSingular}`;
    }

    newObj["id"] = newId;

    onChange([...items, newObj]);
    setNewItems([...newItems, newId]);
  };

  const updateItem = (itemId, k, v) => {
    onChange(
      items.map((f) => {
        if (f.id === itemId) {
          return { ...f, [k]: v };
        }
        return f;
      })
    );
  };

  const deleteItem = (itemId) => {
    onChange(items.filter((f) => f.id !== itemId));
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
      dragHandleSelector=".drag-item"
      lockAxis="y"
      onDrop={(e) => {
        const { addedIndex, removedIndex } = e;
        const movedItems = arrayMove(items, removedIndex, addedIndex);
        onChange(movedItems);
      }}
    >
      {items.map((f, itemIndex) => (
        <Draggable key={f.id}>
          <Item
            passIdToChild={data.passIdToChild}
            spreadsheet={data.spreadsheet}
            width={data.width}
            data={f}
            background={data.background}
            icon={f.icon}
            itemClick={data.itemClick ? () => data.itemClick(itemIndex) : null}
            label={data.getItemLabel ? data.getItemLabel(f) : f.label}
            onChange={(k, v) => updateItem(f.id, k, v)}
            fields={fields.map((field) => ({
              ...field,
              value: get(f, field.key || field.id),
            }))}
            onDelete={() => deleteItem(f.id)}
            labelSingular={labelSingular}
          />
        </Draggable>
      ))}
      {!data.disableAdd && (
        <Button
          data={{
            text: `Add ${labelSingular}`,
            onClick: addItem,
            size: "small",
            type: "basic",
            icon: "FiPlus",
          }}
        />
      )}
    </Container>
  );
};

export default MultiForm;

const Item = ({
  onChange,
  itemClick,
  onDelete,
  fields,
  labelSingular,
  label,
  data,
  icon,
  width,
  background,
  passIdToChild,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <div>
      <Tile
        className={"drag-item"}
        label={label}
        onClick={(e) => {
          setAnchorElement(e.currentTarget);
          if (itemClick) {
            itemClick();
          }
        }}
        onDelete={onDelete}
        icon={icon}
      />

      <Menu
        background={background}
        anchorElement={anchorElement}
        hide={() => setAnchorElement(null)}
        label={`Edit ${labelSingular}`}
        width={width}
      >
        <Form
          sectionPadding="0px"
          onChange={(k, v) => onChange(k, v)}
          fields={fields
            .filter(
              (f) =>
                !f.displayCondition ||
                (f.displayCondition && f.displayCondition(data))
            )
            .map((f) => {
              let obj = {
                ...f,
                activeField: data,
              };

              if (passIdToChild) {
                obj["multiFormItemId"] = data.id;
              }

              return obj;
            })}
        />
      </Menu>
    </div>
  );
};

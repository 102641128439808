import { rConfirmationModalData, rFlowChanges } from "utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Icon } from "components";
import { colors } from "theme/colors";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const BackNavigation = () => {
  const flowChanges = useRecoilValue(rFlowChanges);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const navigate = useNavigate();

  return (
    <Container
      onClick={() => {
        const back = () => {
          navigate("/flows");
        };

        if (flowChanges) {
          setConfirmationModalData({
            title: "You have unsaved changes",
            confirmText: "Leave without saving",
            text: "Are you sure you want to leave this flow without saving? Your changes will be lost.",
            confirm: back,
          });
        } else {
          back();
        }
      }}
    >
      <Rotate>
        <Icon
          data={{
            icon: "FiLogOut",
            size: 20,
            hover: true,
            color: colors.grey3,
          }}
        />
      </Rotate>
    </Container>
  );
};

export default BackNavigation;

const Container = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0 20px 0 20px;
  border-right: 1px solid ${colors.grey2};
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const Rotate = styled.div`
  transform: rotate(180deg);
`;

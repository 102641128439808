export const colors = {
  default: "#202223",
  primary: "#0f48eb",
  primaryUltraLight: "#f0f2ff",
  grey1: "#f8f9fc",
  grey15: "#edefF2",
  grey2: "#e1e4e8",
  grey3: "#8e949e",
  grey4: "#3e4b60",
  grey5: "#1d293d",
  inputBorder: "#d5d7da",
  red: "#df516e",
  divider: "#d5d7da",
};

import { Button, SkeletonLoader, Text, Thumbnail } from "components";
import { get, startCase } from "lodash";

import { colors } from "theme/colors";
import { rTranslations } from "utils/recoil";
import styled from "styled-components";
import useAdmin from "hooks/useAdmin";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const ViewOnlyList = ({
  displayFields,
  formState,
  processDynamicText,
  gridLayout,
  maxWidth,
  handleFieldClick,
  darkMode,
  isFetching,
  deleteButton,
}) => {
  const { isAdmin } = useAdmin();

  return (
    <div>
      <InfoListContainer gridLayout={gridLayout} maxWidth={maxWidth}>
        {displayFields.map((item) => {
          const k = get(item, "key");
          const v = get(formState, k);
          return (
            <ValueContainer
              key={k}
              style={{ gridColumn: `span ${get(item, "columnSpan", 1)}` }}
              onClick={(e) => {
                if (isAdmin && handleFieldClick) {
                  handleFieldClick({ id: k, target: e.currentTarget });
                }
              }}
              handleFieldClick={isAdmin && handleFieldClick}
            >
              {isFetching ? (
                <>
                  <SkeletonLoader
                    height="15px"
                    margin="0 0 10px 0"
                    widthRange={[50, 150]}
                  />
                  <SkeletonLoader height="20px" widthRange={[150, 250]} />
                </>
              ) : (
                <>
                  <Text
                    data={{
                      text: processDynamicText({
                        text: item.label || startCase(k),
                      }),
                      fontStyle: "headingMd",
                      margin: "0 0 5px 0",
                      color: darkMode ? "white" : colors.default,
                    }}
                  />
                  <RenderValue
                    item={item}
                    value={processDynamicText({ text: v })}
                    darkMode={darkMode}
                  />
                </>
              )}
            </ValueContainer>
          );
        })}
      </InfoListContainer>
      {deleteButton && (
        <Button data={{ ...deleteButton, margin: "20px 0 0 0" }} />
      )}
    </div>
  );
};

export default ViewOnlyList;

const RenderValue = ({ value, item, darkMode }) => {
  const { type, componentId, truncate = 200 } = item;

  const translations = useRecoilValue(rTranslations);

  const [expanded, setExpanded] = useState(false);

  if (type === "image" || componentId === "ImageUpload") {
    return <Thumbnail src={value} size="large" />;
  }

  const linkClick = null;

  const truncateAmount = truncate || 35;

  const finalValue = get(item, "linkText") || value;

  if (linkClick) {
    return (
      <Text
        data={{
          text: truncate(finalValue, { length: truncateAmount }),
          onClick: linkClick,
          color: colors.primary,
          fontStyle: "bodyLg",
          cursor: "pointer",
          overflowWrap: "anywhere",
          whiteSpace: true,
          allowSelect: true,
        }}
      />
    );
  }

  return (
    <>
      <Text
        data={{
          text:
            truncate && !expanded
              ? truncate(value, { length: truncate })
              : value,
          fontStyle: "bodyLg",
          overflowWrap: "anywhere",
          allowSelect: true,
          whiteSpace: "pre-wrap",
          color: darkMode ? "white" : colors.default,
        }}
      />
      {truncate && get(value, "length") > truncate && !expanded && (
        <Text
          data={{
            text: get(translations, "showAll", "Show All"),
            fontStyle: "headingSm",
            color: colors.primary,
            margin: "3px 0 0 0",
            onClick: () => setExpanded(true),
            cursor: "pointer",
          }}
        />
      )}
    </>
  );
};

const ValueContainer = styled.div`
  ${(p) =>
    p.handleFieldClick &&
    `
  &:hover{
    outline: 2px solid ${colors.primary};
    outline-offset: -2px;
    cursor: pointer;
    border-radius: 4px;
  }
`};
`;

const InfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(p) => p.maxWidth || "auto"};
  gap: 30px;
  ${(p) =>
    p.gridLayout &&
    `
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    `}
`;

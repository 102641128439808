import MDSpinner from "react-md-spinner";
import { colors } from "theme/colors";

const Spinner = ({ color, size, padding = "0px" }) => (
  <div style={{ padding }} id="spinner">
    <MDSpinner singleColor={color || colors.grey4} size={size || 30} />
  </div>
);

export default Spinner;

import { Icon, Text } from "components";

import { colors } from "theme/colors";
import { get } from "lodash";
import styled from "styled-components";
import useFlow from "hooks/useFlows";

const Header = () => {
  const { flow } = useFlow();

  return (
    <Container>
      <InnerContainer>
        <div>&nbsp;</div>
        <Text
          data={{ text: get(flow, "name"), fontSize: 24, fontWeight: 400 }}
        />
        <Icon data={{ icon: "FiX" }} />
      </InnerContainer>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.inputBorder};
  width: 100%;
  padding: 15px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
`;

import { Button, Form, Icon, Modal, Row, Text } from "components";
import { apiRequest, handleError } from "utils/apiRequests";
import { errorNotification, successNotification } from "utils/notification";
import { get, isEmpty } from "lodash";

import Cookies from "js-cookie";
import { Checkbox as MUICheckbox } from "@mui/material";
import { colors } from "theme/colors";
import curatorLogo from "../assets/curator-logo-blue.png";
import { getCurrentDomain } from "utils/utils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const features = [
  "Easily integrate human approval at every stage",
  "Optimize AI results with tailored, step-by-step guidance",
  "Deploy complex, customizable AI workflows quickly",
];

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = ({ type = "login" }) => {
  const navigate = useNavigate();

  // const location = useLocation();
  // const urlRef = getUrlParameter("ref", location);
  //   const via = Cookies.get("via") || getUrlParameter("via", location);
  //   const ref = Cookies.get("ref") || getUrlParameter("ref", location);

  const [isSendingPasswordEmail, setIsSendingPasswordEmail] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
    full_name: "",
    company_name: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    // Validate form values

    let errors = {};

    if (type === "signup") {
      // validate name and company name
      if (state.full_name.length < 1) {
        errors["full_name"] = "Name required";
      }
      if (state.company_name.length < 1) {
        errors["company_name"] = "Company name required";
      }
    }

    if (!emailRegex.test(state.email)) {
      errors["email"] = "Valid email required";
    }

    if (state.password.length < 6) {
      errors["password"] = "Please enter a more secure password";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const login = () => {
    // Make login request
    if (validate()) {
      setIsFetching(true);
      let finalData = {
        ...state,
        // referral_source: ref || via,
      };
      apiRequest
        .post(`/${type}/`, finalData)
        .then((response) => {
          handleResponse(response);
        })
        .catch((e) => {
          const { message } = handleError(e);
          setErrors({ password: "Invalid password" });
          setIsFetching(false);
          errorNotification(message);
        });
    }
  };

  const handleResponse = (response) => {
    const responseData = get(response, "data");

    const error = get(responseData, "error");
    if (error) {
      errorNotification(error);
      setIsFetching(false);
      setErrors({ password: "Invalid email or password" });
    } else {
      const token = get(responseData, "token");

      Cookies.set("accessToken", token, { expires: 30 });
      setIsFetching(false);

      navigate("/flows");
    }
  };

  const inputPadding = window.innerWidth < 800 ? "9px" : "13px";

  let fields = [
    {
      label: "Email",
      id: "email",
      placeholder: "Email",
      border: errors.email && "1px solid red",
      padding: inputPadding,
      borderRadius: "12px",
    },
    {
      label: "Password",
      id: "password",
      placeholder: "Password",
      type: "password",
      border: errors.password && "1px solid red",
      padding: inputPadding,
      borderRadius: "12px",
    },
  ];

  if (type === "signup") {
    fields = [
      {
        label: "Full Name",
        id: "full_name",
        placeholder: "Full Name",
        padding: inputPadding,
        borderRadius: "12px",
        border: errors.full_name && "1px solid red",
        width: "100%",
      },
      {
        label: "Company Name",
        id: "company_name",
        placeholder: "Company Name",
        padding: inputPadding,
        borderRadius: "12px",
        border: errors.company_name && "1px solid red",
        width: "100%",
      },
      ...fields,
    ];
  }

  return (
    <PublicWrapper>
      {showForgotPassword && (
        <Modal
          minWidth="420px"
          hide={() => setShowForgotPassword(false)}
          header={{
            title: "Forgot Password",
            description: "Enter your email to request a password reset link",
          }}
        >
          <Form
            isFetching={isSendingPasswordEmail}
            submit={() => {
              setIsSendingPasswordEmail(true);
              apiRequest
                .post("/forgot_password/", {
                  email: state.email,
                  domain: getCurrentDomain(),
                })
                .then((r) => {
                  const error = get(r, ["data", "error"]);
                  if (error) {
                    errorNotification(error);
                  } else {
                    successNotification("Password reset email sent");
                    setShowForgotPassword(false);
                    setIsSendingPasswordEmail(false);
                    // mixpanel.track("Reset Password");
                  }
                })
                .catch((error) => {
                  errorNotification(get(error, "data"));
                  setIsSendingPasswordEmail(false);
                });
            }}
            errors={errors}
            submitText={"Send Password Reset Email"}
            onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
            sectionPadding="0px"
            fields={[
              {
                id: "email",
                componentId: "Input",
                label: "Email",
                value: state.email,
              },
            ]}
          />
        </Modal>
      )}

      <Container>
        <LeftContainer>
          <Headline>
            {type === "login" ? "Login To Curator" : "Try Curator Today"}
          </Headline>
          <Text
            data={{
              text: "Build collaborative, multi-step AI flows",
              fontSize: 28,
              fontWeight: 300,
              color: "black",
              margin: "0 0 30px 0",
            }}
          />

          <FeaturesColumn>
            {features.map((f) => (
              <Row alignItems="center" gap="15px">
                <Icon
                  data={{ icon: "FiCheck", color: colors.grey4, size: 16 }}
                />
                <Text
                  data={{
                    text: f,
                    fontSize: 20,
                    fontWeight: 400,
                    color: "rgba(0,0,0, 0.8)",
                    light: true,
                  }}
                />
              </Row>
            ))}
          </FeaturesColumn>
        </LeftContainer>

        <RightContainer>
          <FormContainer>
            <Logo src={curatorLogo} />

            <Form
              fields={fields}
              onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
              errors={errors}
              isFetching={isFetching}
            />

            {type === "signup" && (
              <Row alignItems="center" gap="10px" margin="5px 0 0 0">
                <MUICheckbox
                  color="primary"
                  checked={privacyPolicyAccepted}
                  onChange={() =>
                    setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                  }
                  value={privacyPolicyAccepted}
                  style={{ padding: "0px" }}
                  size="small"
                />

                <div style={{ fontSize: 14 }}>
                  I agree to Curator's{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.frontly.ai/en/articles/8049500-frontly-privacy-policy"
                      )
                    }
                    style={{
                      color: colors.primary,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </span>
                </div>
              </Row>
            )}

            <Button
              data={{
                text: type === "signup" ? "Create Account" : "Login",
                onClick: login,
                width: "100%",
                background: "black",
                padding: inputPadding,
                borderRadius: "12px",
                disabled: !privacyPolicyAccepted && type === "signup",
                size: "large",
                margin: "5px 0 0 0",
                isFetching,
              }}
            />

            <Row justifyContent="center" gap="10px" margin="10px 0 0 0">
              <Text
                data={{
                  text:
                    type === "login"
                      ? "Don't have an account?"
                      : "Already have an account?",
                  color: colors.grey3,
                  fontStyle: "bodyMd",
                }}
              />
              <Text
                data={{
                  text: type === "login" ? "Sign Up" : "Login",
                  color: colors.primary,
                  fontStyle: "headingSm",
                  onClick: () =>
                    navigate(type === "login" ? "/signup" : "/login"),
                }}
              />
            </Row>
          </FormContainer>
          <Text
            data={{
              text: "Forgot password?",
              onClick: () => setShowForgotPassword(true),
              fontStyle: "bodyLg",
              margin: "25px 0 15px 0",
              color: "#ffffff99",
            }}
          />
        </RightContainer>
      </Container>
    </PublicWrapper>
  );
};

export default Login;

const PublicWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: #f2f2f2;
  font-family: "Manrope", "Roboto", sans-serif;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 150px;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Headline = styled.div`
  font-size: 60px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    font-size: 42px;
  }
`;

const FormContainer = styled.div`
  background: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 390px;
  gap: 16px;
  @media screen and (max-width: 800px) {
    width: 100%;
    gap: 15px;
    padding: 25px;
  }
`;

const Logo = styled.img`
  height: 38px;
  width: 100%;
  margin-bottom: 20px;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    height: 32px;
  }
`;

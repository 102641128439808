// import { useLocation, useNavigate } from "react-router-dom";

// import { Pagination as MuiPagination } from "@mui/material";
// import { get } from "lodash";
// import { getUrlParameter } from "utils/utils";
// import { rPagination } from "utils/recoil";
// import { useRecoilValue } from "recoil";

const Pagination = () => {
  // const pagination = useRecoilValue(rPagination);
  // const location = useLocation();
  // const currentPage = parseInt(getUrlParameter("page", location) || 1);
  // const navigate = useNavigate();
  // const onChange = (page) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   searchParams.set("page", page);
  //   navigate(`${location.pathname}?${searchParams.toString()}`);
  // };
  // const pageCount = get(pagination, "page_count", 1);
  // if (pageCount === 1) {
  //   return <></>;
  // }
  // return (
  //   <div style={{ margin: "30px 0 0 0" }}>
  //     <MuiPagination
  //       className="mui-pagination"
  //       variant="outlined"
  //       shape="rounded"
  //       color="primary"
  //       page={currentPage}
  //       count={pageCount}
  //       onChange={(e, page) => onChange(page)}
  //       hideNextButton={!get(pagination, "has_next", false)}
  //       hidePrevButton={!get(pagination, "has_previous", false)}
  //     />
  //   </div>
  // );
};

export default Pagination;

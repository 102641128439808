import * as bootstrap from "react-icons/bs";
import * as feather from "react-icons/fi";
import * as hero from "react-icons/hi2";
import * as md from "react-icons/md";

import {
  AiFillCheckCircle,
  AiOutlineMail,
  AiOutlineNumber,
} from "react-icons/ai";
import { BiCookie, BiTask } from "react-icons/bi";
import { BsGrid3X2, BsSlash } from "react-icons/bs";
import {
  FaDog,
  FaDotCircle,
  FaGoogle,
  FaPaintBrush,
  FaRegDotCircle,
  FaRobot,
  FaShopify,
} from "react-icons/fa";
import { RiFontSize, RiLineHeight } from "react-icons/ri";
import {
  RxDot,
  RxDotFilled,
  RxDragHandleDots2,
  RxDropdownMenu,
  RxInput,
  RxSlider,
  RxSwitch,
} from "react-icons/rx";
import { SiAirtable, SiOpenai } from "react-icons/si";
import {
  TbChartArea,
  TbChartBar,
  TbChartDonut3,
  TbChartLine,
  TbChartPie2,
  TbCircleLetterB,
  TbPlug,
  TbSquareNumber1,
} from "react-icons/tb";
import { VscCircle, VscCircleFilled } from "react-icons/vsc";
import { get, isString } from "lodash";

import { getPixels } from "../utils/utils";
import styled from "styled-components";

const Icon = ({ data }) => {
  const {
    icon,
    defaultIcon,
    size = get(data, "iconSize") || "24px",
    color,
    cursorMove = false,
    margin = "0",
    disabled,
  } = data;

  if (!isString(icon)) {
    // Invalid Icon
    return null;
  }

  const otherIcons = {
    FaRegDotCircle,
    FaDotCircle,
    FaPaintBrush,
    FaDog,
    BiTask,
    TbPlug,
    TbSquareNumber1,
    TbCircleLetterB,
    TbChartBar,
    TbChartDonut3,
    TbChartArea,
    TbChartLine,
    TbChartPie2,
    AiOutlineNumber,
    FaShopify,
    FaGoogle,
    BiCookie,
    AiOutlineMail,
    AiFillCheckCircle,
    SiAirtable,
    SiOpenai,
    BsSlash,
    BsGrid3X2,

    FaRobot,
    RxDot,
    RxSlider,
    RxDotFilled,
    RxInput,
    RxDropdownMenu,
    RxDragHandleDots2,
    RxSwitch,
    VscCircle,
    VscCircleFilled,
    RiFontSize,
    RiLineHeight,
  };

  let hover = get(data, "hover");

  const height = getPixels(get(data, ["styles", "icon", "height"]));

  const getCursor = () => {
    if (disabled) {
      return "not-allowed";
    }

    if (cursorMove) {
      return "move";
    }

    if (hover) {
      return "pointer";
    }

    return "auto";
  };

  const props = {
    size: height || size,
    color,
  };

  const styleProps = {
    cursor: getCursor(),
    margin,
    opacity: disabled ? "0.6" : "1.0",
  };

  const combinedIcons = {
    ...bootstrap,
    ...feather,
    ...hero,
    ...md,
    ...otherIcons,
  };

  if (icon) {
    const NewIcon = get(combinedIcons, icon);
    if (NewIcon) {
      return (
        <NewIcon
          onClick={get(data, "onClick")}
          {...props}
          style={{
            ...styleProps,
            transform: data.rotate ? `rotate(${data.rotate}deg)` : "none",
          }}
        />
      );
    }

    if (defaultIcon) {
      const DefaultIcon = get(combinedIcons, defaultIcon);
      if (DefaultIcon) {
        return (
          <DefaultIcon
            onClick={get(data, "onClick")}
            {...props}
            style={styleProps}
          />
        );
      }
    }
  }

  return null;
};

export default Icon;

const CustomSvg = styled.img`
  height: ${(p) => getPixels(p.height)};
  opacity: ${(p) => (p.disabled ? "0.6" : "1.0")};
  cursor: pointer;
  ${(p) => p.rotate && `transform: rotate(${p.rotate}deg);`}
`;

import {
  Button,
  EditableText,
  Icon,
  Input,
  MarkdownEditor,
  Row,
  Select,
  Switch,
  Text,
  TextArea,
} from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { get, set, startCase } from "lodash";

import { arrayMove } from "utils/utils";
import { colors } from "theme/colors";
import styled from "styled-components";

const HierarchyEditor = ({ schema, data, onChange }) => {
  const handleChange = (path, value) => {
    const newData = { ...data };
    set(newData, path, value);
    onChange(newData);
  };

  const renderField = (schemaItem, schemaPath, dataPath, parentType) => {
    const fieldType = get(schemaItem, "type", "string");

    const componentId = get(schemaItem, "componentId", "Input");

    if (componentId === "MarkdownEditor") {
      return (
        <MarkdownEditor
          data={{
            value: get(data, dataPath, ""),
            onChange: (v) => handleChange(dataPath, v),
          }}
        />
      );
    }

    if (componentId === "Label") {
      return (
        <EditableText
          editable
          fontSize={20}
          fontWeight={600}
          width="100%"
          placeholder={get(schemaItem, "placeholder", "")}
          value={get(data, dataPath, "")}
          onChange={(v) => handleChange(dataPath, v)}
        />
      );
    }

    if (componentId === "Select") {
      return (
        <Select
          data={{
            options: get(schemaItem, "options", []),
            placeholder: schemaItem.placeholder || "Enter text",
            width: "100%",
            value: get(data, dataPath, ""),
            onChange: (v) => handleChange(dataPath, v),
          }}
        />
      );
    }

    if (componentId === "TextArea") {
      return (
        <TextArea
          data={{
            placeholder: schemaItem.placeholder || "Enter text",
            value: get(data, dataPath, ""),
            onChange: (v) => handleChange(dataPath, v),
          }}
        />
      );
    }

    if (componentId === "Switch") {
      return (
        <Switch
          data={{
            value: get(data, dataPath, false),
            onChange: (v) => handleChange(dataPath, v),
          }}
        />
      );
    }

    switch (fieldType) {
      case "string":
        return (
          <Input
            data={{
              placeholder: schemaItem.placeholder || "Enter text",
              width: "100%",
              value: get(data, dataPath, ""),
              onChange: (v) => handleChange(dataPath, v),
            }}
          />
        );
      case "number":
        return (
          <Input
            data={{
              placeholder: schemaItem.placeholder || "Enter number",
              value: get(data, dataPath, 0),
              type: "number",
              onChange: (v) => handleChange(dataPath, parseFloat(v)),
            }}
          />
        );
      case "boolean":
        return (
          <Switch
            data={{
              value: get(data, dataPath, false),
              onChange: (v) => handleChange(dataPath, v),
            }}
          />
        );
      case "array":
        const arrayType = get(schemaItem, "array_type", "string");

        const arrayItems = get(data, dataPath, []);

        const subSchemaItem = get(schemaItem, "keys", []);

        return (
          <div>
            <Container
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
              dragHandleSelector=".drag-item"
              lockAxis="y"
              onDrop={(e) => {
                const { addedIndex, removedIndex } = e;
                const movedItems = arrayMove(
                  arrayItems,
                  removedIndex,
                  addedIndex
                );
                handleChange(dataPath, movedItems);
              }}
            >
              {arrayItems.map((element, index) => {
                return (
                  <Draggable key={index}>
                    <Row className="drag-item" alignItems="center" gap="2px">
                      {/* <div
                        style={{
                          maxWidth: "10px",
                          minWidth: "20px",
                          margin: "0 0 0 -5px",
                        }}
                      >
                        <Icon
                          data={{
                            icon: "MdDragIndicator",
                            size: 20,
                            color: colors.grey2,
                            margin: "2px 0 0 0",
                            cursor: "grab",
                            cursorMove: true,
                          }}
                        />
                      </div> */}

                      {arrayType === "object" ? (
                        renderField(
                          {
                            type: "object",
                            keys: subSchemaItem,
                          },
                          schemaPath,
                          [...dataPath, index],
                          "object"
                        )
                      ) : (
                        <Input
                          data={{
                            placeholder: schemaItem.placeholder || "Enter text",
                            width: "100%",
                            value: element,
                            onChange: (v) => {
                              const newArrayItems = [...arrayItems];
                              newArrayItems[index] = v;
                              handleChange(dataPath, newArrayItems);
                            },
                          }}
                        />
                      )}

                      <div
                        style={{
                          maxWidth: "10px",
                          minWidth: "20px",
                          margin: "0 0 0 5px",
                        }}
                      >
                        <Icon
                          data={{
                            icon: "FiX",
                            size: 20,
                            color: colors.grey2,
                            margin: "2px 0 0 0",
                            hover: true,
                            onClick: () => {
                              handleChange(
                                dataPath,
                                arrayItems.filter((_, i) => i !== index)
                              );
                            },
                          }}
                        />
                      </div>
                    </Row>
                  </Draggable>
                );
              })}
            </Container>
            <Button
              data={{
                text: "Add Item",
                type: "basic",
                size: "small",
                icon: "FiPlus",
                margin: "10px 0 0 0",
                onClick: () =>
                  handleChange(dataPath, [
                    ...arrayItems,
                    arrayType === "object" ? schemaItem.defaultItem || {} : "", // Default new item
                  ]),
              }}
            />
          </div>
        );
      case "object":
        const schemaKeys = get(schemaItem, "keys", []);

        return (
          <ObjectContainer>
            {schemaKeys.map((keyItem, index) => {
              const newSchemaPath = [...schemaPath, "keys", index];
              const newDataPath = [...dataPath, keyItem.key];

              return (
                <div key={index}>
                  <Text
                    data={{
                      text: startCase(keyItem.key),
                      fontSize: 16,
                      fontWeight: 600,
                      margin: "0 0 10px 0",
                    }}
                  />
                  {renderField(keyItem, newSchemaPath, newDataPath, "object")}
                </div>
              );
            })}
          </ObjectContainer>
        );
      default:
        return null;
    }
  };

  return (
    <MainContainer>
      {schema.map((schemaItem, index) => (
        <ObjectKey key={index} hideCard={true}>
          <Text
            data={{
              text: startCase(schemaItem.key),
              fontSize: 16,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          {renderField(schemaItem, [index], [schemaItem.key], "root")}
        </ObjectKey>
      ))}
    </MainContainer>
  );
};

export default HierarchyEditor;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
`;

const ObjectKey = styled.div`
  border-radius: 10px;
  width: fit-content;
  background: white;
  ${(p) =>
    !p.hideCard ? `border: 1px solid ${colors.divider}; padding: 20px;` : ""}
  width: 100%;
`;

const ObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: fit-content;
  background: white;
  gap: 15px;
  ${(p) =>
    !p.hideCard ? `border: 1px solid ${colors.divider};   padding: 20px;` : ""}
  width: 100%;
`;

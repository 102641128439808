import { Button, Tile } from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, safeArray } from "utils/utils";

import { get } from "lodash";
import { rActiveStepId } from "utils/recoil";
import { useSetRecoilState } from "recoil";
import useSteps from "pages/Flow/useSteps";

const StepsList = ({ data }) => {
  const { onChange } = data;

  const { steps, addStep, deleteStep } = useSteps();

  const setActiveStepId = useSetRecoilState(rActiveStepId);

  const items = safeArray(get(data, "value", []));

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
      dragHandleSelector=".drag-item"
      lockAxis="y"
      onDrop={(e) => {
        const { addedIndex, removedIndex } = e;
        const movedItems = arrayMove(items, removedIndex, addedIndex);
        onChange(movedItems);
      }}
    >
      {steps.map((step) => (
        <Draggable key={step.uuid}>
          <Tile
            className={"drag-item"}
            label={step.name || "New Step"}
            onClick={() => setActiveStepId(step.uuid)}
            onDelete={() => deleteStep(step.uuid)}
          />
        </Draggable>
      ))}
      <Button
        data={{
          text: `Add Step`,
          onClick: addStep,
          size: "small",
          type: "basic",
          icon: "FiPlus",
        }}
      />
    </Container>
  );
};

export default StepsList;

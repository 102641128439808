import { get, isEmpty } from "lodash";
import { rFlows, rIsFetchingConfig, rOrganization, rUser } from "utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import { apiRequest } from "utils/apiRequests";
import { decryptConfig } from "utils/encryptionUtils";
import useAdmin from "./useAdmin";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useFetchAppConfig = () => {
  const { isAdmin } = useAdmin();
  const [organization, setOrganization] = useRecoilState(rOrganization);
  const [isFetchingConfig, setIsFetchingConfig] =
    useRecoilState(rIsFetchingConfig);

  const setFlows = useSetRecoilState(rFlows);
  const setUser = useSetRecoilState(rUser);

  const path = useLocation().pathname;

  const fetchAppConfig = () => {
    if (isAdmin && isEmpty(organization) && !isFetchingConfig) {
      // // CLEAR CONFIG
      setFlows([]);
      setUser({});
      setOrganization({});
      setIsFetchingConfig(true);
      apiRequest.get("config/").then((response) => {
        const encryptedConfigBase64 = get(response, "data");
        const decryptedConfig = decryptConfig(encryptedConfigBase64);
        const { flows, organization, user } = decryptedConfig;
        setOrganization(organization);
        setFlows(flows);
        setUser(user);
        setIsFetchingConfig(false);
      });
    }
  };

  // Fetch app config
  useEffect(() => {
    fetchAppConfig({});
  }, [path]);
};

export default useFetchAppConfig;

import { Button, Column, FormInputWrapper, Input, Modal } from "components";
import { get, isEmpty } from "lodash";

import { apiRequest } from "utils/apiRequests";
import { rFlows } from "utils/recoil";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useState } from "react";

const NewFlowModal = ({ hide }) => {
  const [flows, setFlows] = useRecoilState(rFlows);
  const [flowTitle, setFlowTitle] = useState("");
  const [route, setRoute] = useState("new-flow");
  const [isCreating, setIsCreating] = useState(false);
  // const [customizeUrl, setCustomizeUrl] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    let errors = {};

    if (!flowTitle) {
      errors["flowTitle"] = "Please enter a valid flow title";
    }

    if (route.length < 3) {
      errors["route"] = "Please enter a valid flow URL";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const createFlow = () => {
    // Create flow
    if (validate()) {
      setIsCreating(true);
      apiRequest
        .post("/create_flow/", { name: flowTitle, route })
        .then((response) => {
          const data = get(response, "data");
          setFlows([...flows, data]);
          // mixpanel.track("Flow Created");
          navigate(`/flow/edit/${data.uuid}`);
          setIsCreating(false);
        });
    }
  };

  // function stripNonASCII(inputStr) {
  //   // Replace all non-ASCII characters with an empty string
  //   return inputStr.replace(/[^\x00-\x7F]/g, "").replace(" ", "");
  // }

  return (
    <Modal
      minWidth="400px"
      hide={hide}
      header={{
        title: "Create A New Flow",
      }}
    >
      <Column gap="15px">
        <FormInputWrapper
          label="Flow Name"
          hint="The internal name for this flow. This is not visible to end-users."
        >
          <Input
            data={{
              value: flowTitle,
              placeholder: "New Flow",
              error: errors.flowTitle,
              onChange: (v) => {
                setFlowTitle(v);
                // if (!customizeUrl) {
                //   setRoute(v.toLowerCase().replaceAll(" ", "-"));
                // }
              },
            }}
          />
        </FormInputWrapper>

        {/* <FormInputWrapper
          label="Flow URL"
          hint="The location of your flow within your app, used in the browser's address bar to navigate to the flow."
        >
          {customizeUrl ? (
            <Input
              data={{
                value: route,
                placeholder: "new-flow",
                error: errors.route,
                onChange: (v) => {
                  let finalVal = v;
                  finalVal = finalVal.toLowerCase();
                  finalVal = stripNonASCII(finalVal);
                  setRoute(finalVal);
                },
              }}
            />
          ) : (
            <Text
              data={{
                text: route,
                onClick: () => setCustomizeUrl(true),
                link: true,
                margin: "10px 0 0 0",
              }}
            />
          )}

          {!customizeUrl && (
            <Text
              data={{
                text: "Customize URL",
                onClick: () => setCustomizeUrl(true),
                color: colors.primary,
                link: true,
                margin: "10px 0 0 0",
              }}
            />
          )}
        </FormInputWrapper> */}

        <Button
          data={{
            text: "Create Flow",
            onClick: createFlow,
            isFetching: isCreating,
          }}
        />
      </Column>
    </Modal>
  );
};

export default NewFlowModal;

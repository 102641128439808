import { Button, EditableText, Row } from "components";
import { rActiveStepId, rFlowChanges, rSteps } from "utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import BackNavigation from "components/BackNavigation";
import { apiRequest } from "utils/apiRequests";
import { colors } from "theme/colors";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import useFlow from "hooks/useFlows";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [flowChanges, setFlowChanges] = useRecoilState(rFlowChanges);

  const { flow, setFlow } = useFlow();

  const setActiveStepId = useSetRecoilState(rActiveStepId);

  // Important to get from recoil here and not the hook
  const steps = useRecoilValue(rSteps);

  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  return (
    <Container>
      <Row alignItems="center" width="100%">
        <BackNavigation />
        <InnerContainer>
          <EditableText
            editable
            fontSize="20"
            fontWeight="500"
            value={flow.name}
            onChange={(v) =>
              setFlow({
                name: v,
              })
            }
          />

          <Row gap="10px">
            <Button
              data={{
                text: "Preview Flow",
                type: "basic",
                onClick: () => {
                  setActiveStepId(null);
                  navigate(`/flow/${flow.uuid}`);
                },
              }}
            />
            <Button
              data={{
                text: flowChanges ? "Save Changes" : "Changes Saved",
                disabled: !flowChanges,
                isFetching: isSaving,
                onClick: () => {
                  setIsSaving(true);
                  apiRequest
                    .post("/flow_details/", {
                      flow,
                      steps,
                    })
                    .then((res) => {
                      setFlowChanges(false);
                      successNotification("Changes Saved");
                      setIsSaving(false);
                    });
                },
              }}
            />
          </Row>
        </InnerContainer>
      </Row>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: 1px solid ${colors.inputBorder};
  height: 60px;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 15px;
`;

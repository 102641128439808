import MDEditor from "@uiw/react-md-editor";
import { colors } from "theme/colors";
import { getPixels } from "utils/utils";
import styled from "styled-components";
import { truncate } from "lodash";

export const StyledText = styled.div`
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color || p.fontColor || colors.default};
  padding: ${(p) => getPixels(p.padding || 0)};
  margin: ${(p) => getPixels(p.margin || 0)};
  text-align: ${(p) => p.textAlign || "initial"};
  cursor: ${(p) => (p.onClick ? "pointer" : p.cursor || "auto")};
  text-decoration: none !important;
  line-height: ${(p) => p.lineHeight || "auto"};
  white-space: ${(p) => p.whiteSpace || "normal"};
  overflow-wrap: ${(p) => p.overflowWrap || ""};
  width: ${(p) => p.width || "auto"};
  ${(p) =>
    !p.allowSelect &&
    `-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none; user-select: none; display: flex;
  `}
  ${(p) => p.fontFamily && `font-family: ${p.fontFamily};`};
`;

const Text = ({ data }) => {
  let { text } = data;

  if (data.truncate) {
    text = truncate(text, { length: data.truncate });
  }

  if (data.isMarkdown) {
    return (
      <div data-color-mode="light">
        <MDEditor.Markdown
          source={text}
          style={{ background: "transparent" }}
        />
      </div>
    );
  }

  return (
    <StyledText
      fontSize={data.fontSize || 14}
      fontWeight={data.fontWeight || 400}
      {...data}
      fontFamily={data.fontFamily}
    >
      {text}
    </StyledText>
  );
};

export default Text;

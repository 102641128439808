import { Button, Modal, Tile } from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getHighest, safeArray } from "utils/utils";

import HierarchyEditor from "./HierarchyEditor";
import { get } from "lodash";
import { useState } from "react";
import useSteps from "pages/Flow/useSteps";

const Examples = ({ data }) => {
  const { onChange } = data;

  const { activeStep } = useSteps();

  const schema = get(activeStep, "schema", []);

  console.log("schema", schema);

  const items = safeArray(get(data, "value", []));

  const addItem = () => {
    const newId = getHighest(items, "id") + 1;
    let newObj = {
      id: newId,
    };

    onChange([...items, newObj]);
  };

  const updateItem = (itemId, k, v) => {
    onChange(
      items.map((f) => {
        if (f.id === itemId) {
          return { ...f, [k]: v };
        }
        return f;
      })
    );
  };

  const deleteItem = (itemId) => {
    onChange(items.filter((f) => f.id !== itemId));
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
      dragHandleSelector=".drag-item"
      lockAxis="y"
      onDrop={(e) => {
        const { addedIndex, removedIndex } = e;
        const movedItems = arrayMove(items, removedIndex, addedIndex);
        onChange(movedItems);
      }}
    >
      {items.map((f, itemIndex) => (
        <Draggable key={f.id}>
          <Item
            schema={schema}
            data={f}
            onChange={(k, v) => updateItem(f.id, k, v)}
            onDelete={() => deleteItem(f.id)}
          />
        </Draggable>
      ))}
      {!data.disableAdd && (
        <Button
          data={{
            text: "Add Example",
            onClick: addItem,
            size: "small",
            type: "basic",
            icon: "FiPlus",
          }}
        />
      )}
    </Container>
  );
};

export default Examples;

const Item = ({ onChange, data, onDelete, label, schema }) => {
  const [visible, setVisible] = useState(false);

  // TODO - Figure out how to default the data?

  return (
    <div>
      <Tile
        className={"drag-item"}
        label={label}
        onClick={() => setVisible(true)}
        onDelete={onDelete}
      />

      {visible && (
        <Modal
          hide={() => setVisible(false)}
          header={{
            title: "Edit Example",
          }}
        >
          <HierarchyEditor data={data} schema={schema} onChange={onChange} />
        </Modal>
      )}
    </div>
  );
};

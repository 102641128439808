import { rActiveStepId, rFlow, rFlowChanges, rSteps } from "utils/recoil";

import { get } from "lodash";
import { safeArray } from "utils/utils";
import { useRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

const useSteps = () => {
  const [flow, setFlow] = useRecoilState(rFlow);
  const [stepsRecoil, setStepsRecoil] = useRecoilState(rSteps);
  const [flowChanges, setFlowChanges] = useRecoilState(rFlowChanges);

  const steps = safeArray(stepsRecoil);

  const [activeStepId, setActiveStepId] = useRecoilState(rActiveStepId);

  const activeStep = steps.find((s) => s.uuid === activeStepId);

  const setSteps = (steps) => {
    setFlowChanges(true);
    setStepsRecoil(steps);
  };

  const updateStep = (k, v, stepId) =>
    setSteps(
      steps.map((s, i) => {
        if (s.uuid === stepId) {
          return { ...s, [k]: v, __changed: true };
        }
        return s;
      })
    );

  const addStep = (data) => {
    const { parent, child } = data;

    const newUuid = uuidv4();

    if (child) {
      // Add new intermediate step above the given 'child' step

      let matchingStep = steps.find((s) => s.uuid === child);

      let modifiedSteps = steps.map((s) => {
        if (s.parent === matchingStep.parent) {
          return { ...s, parent: newUuid };
        }
        return s;
      });

      let newSteps = [
        ...modifiedSteps,
        { name: "New Step", parent: matchingStep.parent, uuid: newUuid }, // Use newUuid here
      ];

      setSteps(newSteps);
    } else {
      // Add new child to a parent
      setSteps([...steps, { name: "New Step", parent, uuid: newUuid }]);
    }

    setActiveStepId(newUuid);
  };

  const deleteStep = (sId) => {
    const matchingStep = steps.find((s) => s.uuid === sId);

    const newSteps = steps
      .filter((s) => s.id || (!s.id && s.uuid !== sId)) // If step doesn't have an id, remove it entirely
      .map((s) => {
        if (s.parent === sId) {
          // If step has an id, move it to the new parent
          return { ...s, parent: matchingStep.parent };
        }
        if (s.uuid === sId) {
          // If step has an id, mark it as deleted
          return { ...s, __deleted: true };
        }
        return s;
      });

    setActiveStepId(null);

    setSteps(newSteps);
  };

  const getStepsWithIntro = () => {
    // Create the intro step output structure
    let introStep = {};
    let introStepOutput = {};

    const addIntroStep = get(flow, ["data", "intro_step"], false);
    const introStepInputs = get(flow, ["data", "intro_inputs"], []);
    if (addIntroStep) {
      introStep = {
        uuid: null,
        name: get(flow, ["data", "intro_progress_label"], "Intro Step"),
        title: get(flow, ["data", "intro_title"], ""),
        description: get(flow, ["data", "intro_description"], ""),
        schema: introStepInputs,
      };
    }

    const finalSteps = addIntroStep ? [introStep, ...stepsRecoil] : stepsRecoil;

    return finalSteps.filter((s) => !s.__deleted);
  };

  return {
    addStep,
    deleteStep,
    updateStep,
    activeStep,
    steps: getStepsWithIntro(),
  };
};

export default useSteps;
